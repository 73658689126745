import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "fr", name: "Français", flag: "/icons/fr.png" },
  { code: "en", name: "English", flag: "/icons/en.png" },
  { code: "de", name: "Deutsch", flag: "/icons/de.png" },
  { code: "es", name: "Español", flag: "/icons/es.png" },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  const handleChangeLanguage = (code) => {
    i18n.changeLanguage(code).then(() => console.log(`Langue changée en : ${code}`))
      .catch((err) => console.error("Erreur changement de langue :", err));
    setIsOpen(false);
  };

  return (
    <div className="relative">
      {/* Bouton principal avec drapeau */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center p-2 rounded-md hover:bg-gray-300"
      >
        <img 
          src={currentLanguage.flag} 
          alt={currentLanguage.code} 
          className="w-8 h-6 object-cover rounded-sm" 
        />
      </button>

      {/* Liste déroulante */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded-md shadow-lg z-50">
          <ul className="py-2">
            {languages.map(({ code, name, flag }) => (
              <li key={code}>
                <button
                  onClick={() => handleChangeLanguage(code)}
                  className="flex items-center w-full px-4 py-2 text-left hover:bg-gray-100"
                >
                  <img src={flag} alt={code} className="w-8 h-6 mr-2 object-cover rounded-sm" /> {name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
