import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaCreditCard, FaPaypal, FaLock } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


const ProposeDonationPage = () => {
    const [searchParams] = useSearchParams();
	const { id_user, id_admin } = useParams();
    const defaultAmount = parseFloat(searchParams.get("amount")) || 10;
    const currency = searchParams.get("currency") || "EUR";
    const redirectUrl = searchParams.get("redirect") || `/event/success/${id_user}/${id_admin}`;
    const showSkipButton = searchParams.get("showSkip") !== "false"; // Par défaut, le bouton est affiché

    const [amount, setAmount] = useState(defaultAmount);
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState(null);
	const { t } = useTranslation();

    const handlePayment = (method) => {
        setPaymentMethod(method);
    };
	
    const redirectToPayPal = () => {
        window.location.href = `https://www.paypal.com/checkoutnow?amount=${amount}`;
    };


    const handleDonation = () => {
        navigate(`/event/payment?amount=${amount}&currency=${currency}&redirect=${redirectUrl}`);
    };

    const handleSkipDonation = () => {
        navigate(redirectUrl);
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full text-center">
                <h1 className="text-2xl font-bold text-gray-800">{t("donation.title")}</h1>
                <p className="text-gray-600 mt-2">
                    {t("donation.description")}
                </p>
                
                {/* Sélecteur de montant */}
                <div className="mt-6">
                    <label className="block text-gray-700 font-medium text-left text-center">{t("donation.select_amount")}</label>
                    <div className="flex mt-2 max-w-xs w-auto mx-auto">
                        <input 
                            type="number"
                            min="1"
                            value={amount}
                            onChange={(e) => setAmount(Number(e.target.value))}
                            className="flex-1 px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                        <span className="px-4 py-2 bg-gray-200 border border-gray-300 rounded-r-md">€</span>
                    </div>
                </div>


                <div className="mt-6 flex justify-center space-x-6">
                    <button onClick={() => handlePayment("CB")} className="flex items-center bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-600 transition">
                        <FaCreditCard className="mr-2" /> {t("donation.pay_by_card")}
                    </button>
                    <button onClick={() => handlePayment("PayPal")} className="flex items-center bg-yellow-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-yellow-600 transition">
                        <FaPaypal className="mr-2" /> {t("donation.pay_by_paypal")}
                    </button>
                    {showSkipButton && (
                        <button onClick={handleSkipDonation} className="flex items-center bg-red-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-red-600">
                            {t("donation.skip_donation")}
                        </button>
                    )}
                </div>

                <p className="text-sm text-gray-500 mt-4">
                    {t("donation.thank_you")}
                </p>
				
				
				{paymentMethod === "CB" && (
					<div className="mt-6 p-6 border rounded-lg shadow-md">
						<h2 className="text-xl font-semibold text-gray-700 mb-4">Paiement sécurisé de {amount}€ par Carte Bancaire</h2>
						<form>
							<label className="block text-sm font-medium text-gray-700">Numéro de Carte</label>
							<input type="text" className="w-full p-3 border rounded-md mb-4" placeholder="1234 5678 9012 3456" />
							
							<div className="grid grid-cols-2 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">Date d'Expiration</label>
									<input type="text" className="w-full p-3 border rounded-md" placeholder="MM/AA" />
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">CVV</label>
									<input type="text" className="w-full p-3 border rounded-md" placeholder="123" />
								</div>
							</div>
							
							<button type="submit" className="mt-6 w-full bg-green-500 text-white py-3 rounded-md shadow-md hover:bg-green-600 transition flex items-center justify-center">
								<FaLock className="mr-2" /> Valider le Paiement
							</button>
						</form>
					</div>
				)}
				
				{paymentMethod === "PayPal" && (
					<div className="mt-6 p-6 border rounded-lg shadow-md text-center">
						<h2 className="text-xl font-semibold text-gray-700 mb-4">Paiement via PayPal</h2>
						<p className="text-gray-600">Vous allez être redirigé vers PayPal pour finaliser le paiement.</p>
						<button onClick={redirectToPayPal} className="mt-4 bg-yellow-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-yellow-600 transition">
							Continuer vers PayPal
						</button>
					</div>
				)}				
				
				
				
            </div>
			

			
        </div>
    );
};

export default ProposeDonationPage;
