import React, { useEffect, useState } from "react";
import EventAdmin from "./EventAdmin";
import EventAdminMobile from "./EventAdminMobile";

const EventAdminRedirect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile === null) return null; // Optionnel: loader/spinner

  return isMobile ? <EventAdminMobile /> : <EventAdmin />;
};

export default EventAdminRedirect;
