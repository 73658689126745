/* global grecaptcha */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaCloudUploadAlt, FaLock, FaGlobe, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EventAdmin = () => {
    const { id_admin } = useParams();
    const navigate = useNavigate();
    const [eventData, setEventData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [logoFile, setLogoFile] = useState(null);
	const [logoPreview, setLogoPreview] = useState(null);
    const [newMaxParticipants, setNewMaxParticipants] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
	const apiBaseLogoUrl = process.env.REACT_APP_LOGO_BASE_URL
    const priceUnit = Number(process.env.REACT_APP_PRICE_UNIT);
	const [email, setEmail] = useState("");
    const [isEmailVisible, setIsEmailVisible] = useState(false);
    const [phone, setPhone] = useState("");
    const [isPhoneVisible, setIsPhoneVisible] = useState(false);
    const [phoneError, setPhoneError] = useState("");

    useEffect(() => {
        const fetchEvent = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${apiBaseUrl}/api/events/admin/${id_admin}`);
                if (!response.ok) throw new Error("Erreur lors du chargement de l'événement");
                const data = await response.json();
                setEventData(data);
                setNewMaxParticipants(data.max_participants);
                setEmail(data.contact || "");
                setIsEmailVisible(data.is_email_visible);
                setPhone(data.telephone || "");
                setIsPhoneVisible(data.is_telephone_visible);
				if (data.logo) {
                    setLogoPreview(`${apiBaseLogoUrl}/${data.logo}`);
                }
            } catch (error) {
                console.error("Erreur de chargement de l'événement:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchEvent();
    }, [id_admin, apiBaseUrl]);


    const formatDate = (dateString) => {
        if (!dateString) return "";
        return new Date(dateString).toLocaleDateString(navigator.language, {
            year: "numeric",
            month: "long",
            day: "numeric"
        });
    };
	
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (logoPreview) {
                URL.revokeObjectURL(logoPreview);
            }
            setLogoFile(file);
            setLogoPreview(URL.createObjectURL(file));
        }
    };

    const handleSaveChanges = async () => {
        if (!eventData) return;
        
        if (!eventData.is_public && newMaxParticipants > eventData.max_participants) {
            const additionalParticipants = newMaxParticipants - eventData.max_participants;
            const paymentAmount = (additionalParticipants * priceUnit).toFixed(2);
            console.log("💳 Redirection vers la page de paiement avec montant :", paymentAmount);
            navigate(`/event/payment?amount=${paymentAmount}`);
            return;
        }

        const formData = new FormData();
        formData.append("title", eventData.title);
        formData.append("contact", eventData.contact);
        formData.append("max_participants", newMaxParticipants);
		

        
        if (logoFile) {
            formData.append("logo", logoFile);
        }

        try {
            const response = await fetch(`${apiBaseUrl}/api/events/admin/${id_admin}`, {
                method: "PUT",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la mise à jour");
            }

            // Rediriger vers la page de l'événement après la mise à jour
            navigate(`/event/user/${eventData.id_user}`);
        } catch (error) {
            console.error("Erreur lors de l'enregistrement :", error);
            alert("Une erreur est survenue lors de la mise à jour.");
        }
    };

    if (isLoading) return <p>Chargement...</p>;
    if (!eventData) return <p>Événement introuvable</p>;

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-4">Modifier votre événement <i>"{eventData.title}"</i>:</h1>
            <div className="flex items-center space-x-4 mb-6">
                {eventData.logo && (
                    <img src={`${apiBaseLogoUrl}/${eventData.logo}`} alt="Logo" className="w-40 h-40 object-contain" />
                )}
                <div>
                    <p className="text-gray-600"><strong>Adresse :</strong> {eventData.location}</p>
                    <p className="text-gray-600">
                        <strong>Date :</strong> {eventData.event_type === "saisonnier" 
                        ? `${formatDate(eventData.start_date)} - ${formatDate(eventData.end_date)}`
                        : `${formatDate(eventData.date)} à ${eventData.time}`}
                    </p>
                    <p className="text-gray-600"><strong>Email :</strong> {eventData.contact}</p>
                    <p className="text-gray-600 flex items-center space-x-2">
                        {eventData.is_public ? <FaGlobe className="text-green-500" /> : <FaLock className="text-red-500" />} 
                        <span>{eventData.is_public ? "Événement Public" : "Événement Privé"}</span>
                    </p>
                </div>
            </div>

			<div className="space-y-4">
                <div className="grid grid-cols-4 gap-4 items-center">
                    <div className="w-full">
                        <label htmlFor="logo" className="block text-sm font-medium text-gray-700">
                            Logo du site
                        </label>
                        <div className="mt-2 flex flex-col items-center justify-center border border-gray-300 rounded-md shadow-sm p-2 bg-gray-100 cursor-pointer hover:bg-gray-200 transition duration-200">
                            <label htmlFor="logo" className="flex flex-col items-center cursor-pointer">
                                {logoPreview ? (
                                    <div className="flex flex-col items-center">
                                        <img src={logoPreview} alt="Aperçu du logo" className="w-24 h-24 object-contain" />
                                        <p className="mt-1 text-sm text-gray-700 font-semibold">Changer le fichier</p>
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-2">
                                        <FaCloudUploadAlt className="text-blue-500 text-3xl" />
                                        <span className="text-sm text-gray-600">Cliquez pour choisir un autre logo</span>
                                    </div>
                                )}
                                <input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>

				{/* Email du Responsable */}
				<div className="grid grid-cols-2 gap-4">
					<div className="sm:col-span-1 relative">
						<label htmlFor="contact" className="block text-sm font-medium text-gray-700 mb-2">
							Adresse email du Responsable
						</label>
						<div className="relative flex items-center h-12">
							<input
								type="email"
								id="contact"
								name="contact"
								required
								placeholder="Email du responsable"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="block w-full p-4 pr-12 border border-gray-300 rounded-md shadow-sm h-full"
							/>
							<button
								type="button"
								onClick={() => setIsEmailVisible(!isEmailVisible)}
								className="absolute right-0 flex items-center justify-center w-10 h-full bg-gray-200 hover:bg-gray-300 rounded-r-md text-gray-600"
								title="Afficher l'email sur votre page"
							>
								{isEmailVisible ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-gray-500" />}
							</button>
						</div>
						<p className="text-1xl text-gray-600 mt-1">
							{isEmailVisible
								? "Vous avez choisi de rendre visible l'adresse email sur votre site."
								: "Vous avez choisi de ne pas rendre visible l'adresse email sur votre site."}
						</p>
					</div>
					{/* Téléphone du Responsable */}
					<div className="sm:col-span-1 relative">
						<label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">
							Téléphone du Responsable
						</label>
						<div className="relative flex items-center h-12">
							<PhoneInput
								country={"fr"}
								value={phone}
								onChange={(value) => {
									setPhone(value);
									setPhoneError(value.trim() ? "" : "Numéro invalide");
								}}
								inputProps={{
									name: "phone",
									required: false,
									className: "block w-full p-4 pl-14 pr-12 border border-gray-300 rounded-md shadow-sm h-full",
								}}
							/>
							<button
								type="button"
								onClick={() => setIsPhoneVisible(!isPhoneVisible)}
								className="absolute inset-y-0 right-0 flex items-center justify-center w-10 bg-gray-200 hover:bg-gray-300 rounded-r-md text-gray-600"
								title="Afficher le téléphone sur votre page"
							>
								{isPhoneVisible ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-gray-500" />}
							</button>
						</div>
						<p className="text-sm text-gray-600 mt-1">
							{isPhoneVisible
								? "Vous avez choisi de rendre visible ce n° de téléphone sur votre site."
								: "Vous avez choisi de ne pas rendre visible ce n° de téléphone sur votre site."}
						</p>
					</div>
				</div>
				<div className="grid grid-cols-2 gap-4">
					{eventData.event_type === "saisonnier" ? (
						<>
							<label>Début :
								<input 
									type="date" 
									value={eventData.start_date} 
									onChange={(e) => setEventData({ ...eventData, start_date: e.target.value })} 
									className="border p-2 w-full" 
								/>
							</label>
							<label>Fin :
								<input 
									type="date" 
									value={eventData.end_date} 
									onChange={(e) => setEventData({ ...eventData, end_date: e.target.value })} 
									className="border p-2 w-full" 
								/>
							</label>
						</>
					) : (
						<>
							<label>Date :
								<input 
									type="date" 
									value={eventData.date} 
									onChange={(e) => setEventData({ ...eventData, date: e.target.value })} 
									className="border p-2 w-full" 
								/>
							</label>
							<label>Heure :
								<input 
									type="time" 
									value={eventData.time} 
									onChange={(e) => setEventData({ ...eventData, time: e.target.value })} 
									className="border p-2 w-full" 
								/>
							</label>
						</>
					)}
				</div>
			</div>


            {!eventData.is_public && (
                <div className="mt-4">
                    <p><strong>Nombre maximum de participants :</strong> {eventData.max_participants || 0}</p>
                    <p><strong>Nombre actuel de participants :</strong> {eventData.current_participants || 0}</p>
                    <label className="block mt-2">Modifier le nombre maximum de participants
                        <input type="number" value={newMaxParticipants} onChange={(e) => setNewMaxParticipants(Number(e.target.value))} className="border p-2 w-full" />
                    </label>
                    {newMaxParticipants > eventData.max_participants && (
                        <p className="text-red-500 mt-2">
                            ⚠️ Contribution aux frais de fonctionnement : {(newMaxParticipants - eventData.max_participants) * priceUnit}€
                        </p>
                    )}
                </div>
            )}

            <button 
                onClick={handleSaveChanges} 
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
                Enregistrer les modifications
            </button>
        </div>
    );
};

export default EventAdmin;
