import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import EventMap from "../components/Map/EventMap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
												
    const MatchRequestCard = ({ request, user, onRespond, onRecenterMap, event, refreshMatchRequests }) => {
    const [receiver, setReceiver] = useState(null);
    const [sender, setSender] = useState(null);
	const [eventTitle, setEventTitle] = useState("");
	const [isReceiver, setIsReceiver] = useState(false);
	const [isSender, setIsSender] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showContactModal, setShowContactModal] = useState(false);
	const [message, setMessage] = useState("");
	const [loadingEmail, setLoadingEmail] = useState(false);
	const [sentSuccess, setSentSuccess] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const priceContact = Number(process.env.REACT_APP_PRICE_CONTACT);
	const [eventsLoaded, setEventsLoaded] = useState(false);
	const { t } = useTranslation();



    useEffect(() => {
        // Récupérer les infos du receiver
		const fetchReceiver = async () => {
			try {
				const response = await api.get(`/api/participants/${request.receiver_id}`);
				setReceiver(response.data);
			} catch (error) {
				console.error("❌ Erreur lors de la récupération du receiver :", error);
			}
		};

		const fetchSender = async () => {
			try {
				const response = await api.get(`/api/participants/${request.sender_id}`);
				setSender(response.data);
			} catch (error) {
				console.error("❌ Erreur lors de la récupération du sender :", error);
			}
		};


        const fetchEvent = async () => {
            try {
                const response = await api.get(`/api/events/${request.event_id}`); // 📌 Récupère l'événement
                setEventTitle(response.data.title); // 📌 Stocke le titre
            } catch (error) {
                console.error("❌ Erreur lors de la récupération de l'événement :", error);
            }
        };
		
        fetchReceiver();
        fetchSender();
		fetchEvent();
		
    }, [request.receiver_id, request.sender_id, request.event_id]);   // Déclenche le useEffect à chaque changement des IDs
	
	
	useEffect(() => {
        if (receiver && user && receiver.email === user.email) {
            setIsReceiver(true);
        } else {
            setIsReceiver(false);
        }

        if (sender && user && sender.email === user.email) {
            setIsSender(true);
        } else {
            setIsSender(false);
        }
    }, [receiver, sender, user]);


	const handleCardClick = () => {
        if (isReceiver && sender) {
            onRecenterMap(sender.latitude, sender.longitude);
        } else if (isSender && receiver) {
            onRecenterMap(receiver.latitude, receiver.longitude);
        }
    };
	
	const handleCancelRequest = () => {
		setShowCancelModal(true);
	};
	
	const confirmCancelRequest = async () => {
        try {
            await api.delete(`/api/match-requests/${request.id}`);
            
            await api.post("/api/participants/send_match_cancel_email", { 
				receiver_email: receiver.email,
				sender_name: sender.name,
				event_title: eventTitle
			});
			setShowCancelModal(false);
			refreshMatchRequests();
        } catch (error) {
            console.error("❌ Erreur lors de l'annulation de la demande :", error);
            alert("Erreur lors de l'annulation de la demande.");
        }
    };
	

	const handleRejectRequest = () => {
		setShowRejectModal(true); // ✅ Ouvre le modal
	};


	const confirmRejectRequest = async () => {
		try {
			// 🔍 Suppression de la demande dans la base de données
			await api.delete(`/api/match-requests/${request.id}`);

			// 📩 Envoi d'un email au demandeur pour l'informer du refus
			await api.post("/api/participants/send_rejection_email", {
				to_email: sender.email,
				sender_name: sender.name,
				receiver_name: receiver.name,
				event_title: eventTitle
			});

			refreshMatchRequests(); // 🔄 Rafraîchir la toolbar après suppression
			setShowRejectModal(false); // ✅ Fermer le modal

		} catch (error) {
			console.error("❌ Erreur lors du refus de la demande :", error);
			alert("Une erreur est survenue lors du refus de la demande.");
		}
	};


	return (
		<div 
            className="p-4 border rounded-lg shadow-md bg-white flex justify-between items-center cursor-pointer"
            onClick={handleCardClick}
        >
			<div>
				{isReceiver ? (
					<p className="text-gray-800 font-semibold">
						  {sender ? `En relation avec ${sender.name}` : t("landing.loading")}</p>
				) : isSender ? (
					<p className="text-gray-800 font-semibold">
						  {receiver ? `En relation avec ${receiver.name}` : t("landing.loading")}
					</p>
				) : null}
			</div>
			  <>
				<div className="flex space-x-2">
				  <button
				    className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 ml-4"
				    onClick={handleCancelRequest}
				  >
				    {t("dashboard.reject")}
				  </button>
				  <button
					className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ml-2"
					onClick={() => { setLoadingEmail(false); setSentSuccess(false); setShowContactModal(true); }}
				  >
					Contacter
				  </button>
				</div>
				{showContactModal && (
				  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
					<div className="bg-white rounded-lg shadow-lg p-6 w-[600px]">
					  <h2 className="text-xl font-bold text-gray-800 mb-4">Envoyer un message à {receiver?.name}</h2>
					  {loadingEmail ? <p className="text-gray-500 mb-4 text-center">⏳ Veuillez patienter, envoi en cours...</p> : sentSuccess ? <p className="text-green-600 mb-4 text-center">✅ Message envoyé avec succès.</p> : null}
<textarea
						className="w-full p-3 border rounded-md h-40 resize-none"
						placeholder="Votre message..."
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					  />
					  <div className="flex justify-end space-x-4 mt-4">
						<button
						  onClick={() => { setShowContactModal(false); setSentSuccess(false); setLoadingEmail(false); }}
						  className="px-4 py-2 text-gray-600 border border-gray-400 rounded-md hover:bg-gray-100"
						>
						  Annuler
						</button>
						<button
						  onClick={async () => {
							try {
							  await api.post("/api/participants/send_email_contact", {
								to_email: receiver.email,
								sender_name: sender.name,
								event_title: eventTitle,
								message
							  });
							  setShowContactModal(false);
							} catch (err) {
							  alert("Erreur lors de l'envoi du message");
							  console.error(err);
							}
						  }}
						  disabled={loadingEmail || sentSuccess}
            className={`px-4 py-2 rounded-md transition-all ${
                loadingEmail ? "bg-gray-400 cursor-not-allowed" :
                sentSuccess ? "bg-green-500 text-white" :
                "bg-blue-500 text-white hover:bg-blue-600"
            }`}
						>
						  Envoyer
						</button>
					  </div>
					</div>
				  </div>
				)}
				</>
			{showCancelModal && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
					<div className="bg-white rounded-lg shadow-lg p-6 w-96">
						<h2 className="text-xl font-bold text-gray-800 mb-4">Confirmation d'annulation</h2>
						<p className="text-gray-600 mb-6">{t("dashboard.confirm_cancel_request")}</p>
						<div className="flex justify-end space-x-4">
							<button
								onClick={() => setShowCancelModal(false)}
								className="px-4 py-2 text-gray-600 border border-gray-400 rounded-md hover:bg-gray-100 transition-all"
							>
								{t("dashboard.cancel")}
							</button>
							<button
								onClick={confirmCancelRequest}
								className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-all"
							>
								{t("dashboard.confirm")}
							</button>
						</div>
					</div>
				</div>
			)}
			{showRejectModal && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
					<div className="bg-white rounded-lg shadow-lg p-6 w-96 animate-fadeIn">
						<h2 className="text-xl font-bold text-gray-800 mb-4">{t("dashboard.confirmation")}</h2>
						<p className="text-gray-600 mb-6">
						{t("dashboard.confirm_reject_request", { sender: sender?.name, event: eventTitle })}
						</p>
						<div className="flex justify-end space-x-4">
							<button
								onClick={() => setShowRejectModal(false)}
								className="px-4 py-2 text-gray-600 border border-gray-400 rounded-md hover:bg-gray-100 transition-all"
							>
								{t("dashboard.cancel")}
							</button>
							<button
								onClick={confirmRejectRequest}
								className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-all"
							>
								{t("dashboard.confirm")}
							</button>
						</div>
					</div>
				</div>
			)}
			
			
		</div>
	);
};




const Dashboard = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]); // Paris par défaut
    const navigate = useNavigate();
	const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
	const [matchRequests, setMatchRequests] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
	const [eventsLoaded, setEventsLoaded] = useState(false);
	const logoBaseUrl = process.env.REACT_APP_LOGO_BASE_URL;
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();

    // Vérifier l'utilisateur connecté
	useEffect(() => {
		const fetchUser = async () => {
			let token = localStorage.getItem("authToken");

			if (!token) {
				console.log("Aucun token trouvé, redirection vers /login");
				setLoading(false);
				navigate("/login");
				return;
			}

			try {
				const response = await api.get("/auth/users/me", {
					headers: { Authorization: `Bearer ${token}` }
				});
				console.log("✅ Utilisateur authentifié :", response.data);
				setUser(response.data);
			} catch (error) {
				console.error("❌ Erreur API /users/me :", error);
				localStorage.removeItem("authToken");
				navigate("/login");
			} finally {
				setLoading(false);
			}
		};
		fetchUser();
	}, [navigate]);


    // Récupérer les événements de l'utilisateur
	useEffect(() => {
		const fetchUserEvents = async () => {
			const eventIdFromQuery = searchParams.get("event_id");

			if (!user) return;
			try {
				const response = await api.get(`/api/events/by-email/${encodeURIComponent(user.email)}`);
				setEvents(response.data);

				if (response.data.length > 0) {
					const eventFromUrl = response.data.find(e => e.id.toString() === eventIdFromQuery);
					setSelectedEvent(eventFromUrl || response.data[0]);
				}
			} catch (error) {
				console.error("Erreur lors de la récupération des événements :", error);
			} finally {
				setEventsLoaded(true); // ✅ indique que le chargement est terminé
			}
		};

		fetchUserEvents();
	}, [user, searchParams]);


    useEffect(() => {
        const fetchRequests = async () => {
            const response = await api.get("/api/match-requests");
            setMatchRequests(response.data);
        };
        fetchRequests();
    }, []);
	
	
    // Déterminer la position de la carte en fonction du participant
	useEffect(() => {
		if (!user || !selectedEvent) return;

		const participant = selectedEvent.participants?.find(p => p.email === user.email);

		if (participant && participant.latitude && participant.longitude) {
			setMapCenter([participant.latitude, participant.longitude]);
		} else if (selectedEvent.latitude && selectedEvent.longitude) {
			setMapCenter([selectedEvent.latitude, selectedEvent.longitude]);
		}
	}, [user, selectedEvent]);

    if (loading) {
        return <div className="text-center text-xl font-bold mt-10">Chargement...</div>;
    }

	// ✅ Si l'utilisateur est bien chargé mais aucun événement
	if (user && eventsLoaded && events.length === 0) {
		return (
			<div className="flex flex-col justify-center items-center h-screen">
				<p className="text-red-600 text-2xl font-semibold text-center">
					{t("dashboard.no_events")}
				</p>
			</div>
		);
	}

	const handleParticipantClick = (participant) => {
		setSelectedParticipant(participant);
		setShowSidebar(true);
	};

	const handleMarkerClick = (participant) => {
		setSelectedParticipant(participant);
		setShowSidebar(true);
	};


	const handleSendNotification = async () => {
		if (!selectedParticipant || !selectedEvent) return;

		try {
			// Envoi de la requête pour créer une `match-request`
			const response = await api.post("/api/match-requests/create", {
				sender_id: user.id,
				receiver_id: selectedParticipant.id,
				event_id: selectedEvent.id
			});

			// Vérifier si la requête a réussi
			if (response.status === 200) {
				alert("Notification envoyée !");
			} else {
				throw new Error("Erreur lors de la création de la demande.");
			}

			// Appel API pour envoyer un email
			await api.post("/api/send-email", {
				to_email: selectedParticipant.email,
				subject: "Demande de mise en relation",
				message: `Bonjour ${selectedParticipant.name},\n\n${user.full_name} souhaite entrer en contact avec vous pour l'événement ${selectedEvent.title}.\n\nVeuillez vous connecter à votre tableau de bord pour répondre à cette demande.\n\nCordialement,\nL'équipe de Covoiturage`
			});

			setShowSidebar(false);
		} catch (error) {
			console.error("Erreur lors de l'envoi de la notification :", error);
			alert("Erreur lors de l'envoi de la notification.");
		}
	};
	
    const handleUnsubscribe = async (eventId) => {
        if (!user) return;
		const encodedEmail = encodeURIComponent(user.email);
        try {
            const response = await api.delete(`/api/participants/remove`, {
                data: { 
                    event_id: eventId, 
                    email: user.email 
                }
            });
    
            // Mettre à jour la liste des événements après suppression
            setEvents((prevEvents) => prevEvents.filter(event => event.id !== eventId));
            setSelectedEvent(null);
            setShowUnsubscribeModal(false); // 🚀 Ferme le modal après suppression
        } catch (error) {
            console.error("❌ Erreur lors de la désinscription :", error);
            alert("Erreur lors de la désinscription. Veuillez réessayer.");
        }
    };

	const refreshMatchRequests = async () => {
		try {
			const response = await api.get("/api/match-requests"); // Recharger la liste
			setMatchRequests(response.data);
		} catch (error) {
			console.error("❌ Erreur lors de la mise à jour des match requests :", error);
		}
	};
	
    return (
        <div className="flex h-screen overflow-hidden">
            <div className="flex-1 h-full relative">
				{user && (
				<EventMap 
					position={mapCenter} 
					markers={[
					  ...(selectedEvent?.participants?.map(participant => ({
						position: participant.latitude && participant.longitude ? [participant.latitude, participant.longitude] : null,
						name: participant.name || "Inconnu",
						email: participant.email,
						id: participant.id,
						address: participant.address,
						fumeur: participant.fumeur,
						chauffeur: participant.chauffeur,
						places: participant.places,
						comment: participant.comment
					  }))?.filter(p => p.position !== null) || []),
					  ...(selectedEvent && selectedEvent.latitude && selectedEvent.longitude
						? [{
							position: [selectedEvent.latitude, selectedEvent.longitude],
							name: selectedEvent.title,
							email: "event",
							id: `event-${selectedEvent.id}`,
							address: selectedEvent.location
						  }]
						: [])
					]}
					userParticipant={selectedEvent?.participants?.find(p => p.email === user?.email) ?? null}
					eventLocation={{
					  latitude: selectedEvent?.latitude ?? 48.8566,
					  longitude: selectedEvent?.longitude ?? 2.3522
					}}
					onMarkerClick={handleMarkerClick}
					user={user}
					selectedEvent={selectedEvent}
					refreshMatchRequests={refreshMatchRequests}
				/>
				)}
            </div>
            <div className="w-1/3 h-screen bg-white p-6 overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 scrollbar-thumb-rounded-md scrollbar-w-1.5">
                {events.length > 0 ? (
                    <>
                        <h1 className="text-2xl font-bold mb-4">{t("dashboard.your_events")}</h1>
                        <div>
						{Array.isArray(events) && events.map((event, index) => (
							<div
								key={index}
								className={`flex items-center p-4 mb-2 cursor-pointer border rounded-lg ${
									selectedEvent?.id === event.id ? "bg-gray-200" : "bg-white"
								}`}
								onClick={() => setSelectedEvent(event)}
							>
								{/* Logo de l'événement */}
								{event.logo && (
									<img src={`${logoBaseUrl}/${event.logo}`} alt="logo" className="w-40 h-40 object-cover rounded-lg mr-4" />
								)}

								{/* Informations de l'événement */}
								<div className="flex flex-col flex-1">
									<div className="flex items-center justify-between w-full">
										{/* Icône cadenas */}
										<div className="flex items-center">
											<img
												src={event.is_public ? "/icons/open-lock.png" : "/icons/closed-lock.png"}
												alt={event.is_public ? t("dashboard.event_public") : t("dashboard.event_private")}
												className="w-5 h-5 mr-2"
												title={event.is_public ? t("dashboard.event_public") : t("dashboard.event_private")}
											/>
											<h1 className="text-xl font-bold text-gray-800">{event.title}</h1>
										</div>

										{/* Bouton "Retirer mon inscription" aligné à droite */}
										<button
											onClick={() => {
												setSelectedEventId(event.id);
												setShowUnsubscribeModal(true);
											}}
											className="px-3 py-1 border border-red-500 text-red-500 rounded-md hover:bg-red-500 hover:text-white transition-all duration-300"
										>
											{t("dashboard.remove")}
										</button>
									</div>

									<p className="text-gray-600">{event.location}</p>

									{event.date && event.time ? (
										<p className="text-gray-500">
											{new Date(event.date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })} à {new Date(`1970-01-01T${event.time}`).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
										</p>
									) : event.start_date && event.end_date ? (
										<p className="text-gray-500">
											Du {new Date(event.start_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })} au {new Date(event.end_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
										</p>
									) : (
										<p className="text-gray-500">Date non spécifiée</p>
									)}

									{/* Affichage conditionnel de l'email */}
									{event.is_email_visible && event.contact && (
										<p className="text-gray-500">{t("dashboard.contact")}: {event.contact}</p>
									)}

									{/* Affichage conditionnel du téléphone */}
									{event.is_telephone_visible && event.telephone && (
										<p className="text-gray-500">📞 {event.telephone}</p>
									)}

									<div key={event.id} className="mb-6">
										<h3 className="text-lg font-bold text-gray-700">{t("dashboard.notification")}</h3>

										{matchRequests.filter(request => request.event_id === event.id).length > 0 ? (
											matchRequests.filter(request => request.event_id === event.id).map((request) => (
											<MatchRequestCard 
												key={request.id}
												request={request} 
												user={user}
												onRespond={handleParticipantClick} 
												onRecenterMap={(lat, lng) => setMapCenter([lat, lng])}
												event={events.find(e => e.id === request.event_id)} 
												refreshMatchRequests={refreshMatchRequests}
											/>
											))
										) : (
											<p className="text-gray-500">{t("dashboard.no_pending_requests")}</p>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
                    </>
                ) : (
                    <div className="flex flex-col justify-center items-center h-full">
					  <p className="text-red-600 text-2xl font-semibold text-center">
						{t("dashboard.no_events")}
					  </p>
					</div>
                )}

			{showUnsubscribeModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                        <h2 className="text-xl font-bold text-gray-800 mb-4">{t("dashboard.confirmation")}</h2>
                        <p className="text-gray-600 mb-6">{t("dashboard.unsubscribe_confirmation")}
						</p>
                        <div className="flex justify-end space-x-4">
                            {/* Bouton Annuler */}
                            <button
                                onClick={() => setShowUnsubscribeModal(false)}
                                className="px-4 py-2 text-gray-600 border border-gray-400 rounded-md hover:bg-gray-100 transition-all"
                            >
								{t("dashboard.cancel")}
                            </button>
            
                            {/* Bouton Confirmer */}
                            <button
                                onClick={() => handleUnsubscribe(selectedEventId)}
                                className="px-3 py-1 border border-red-500 text-red-500 rounded-md hover:bg-red-500 hover:text-white transition-all duration-300"
                            >
                                {t("dashboard.confirmation")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    );
};

export default Dashboard;
