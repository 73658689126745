import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChangePasswordPage = () => {
	const { t } = useTranslation();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        const token = localStorage.getItem("authToken");
        if (!token) {
            setError("Vous devez être connecté pour changer votre mot de passe.");
            return;
        }

        if (!oldPassword || !newPassword || !confirmPassword) {
            setError(t("change_password.error_empty_fields"));
            return;
        }

        if (newPassword !== confirmPassword) {
            setError(t("change_password.error_mismatch"));
            return;
        }

        if (newPassword.length < 8 || !/[A-Z]/.test(newPassword) || !/[0-9]/.test(newPassword)) {
            setError(t("change_password.error_criteria"));
            return;
        }

        try {
            const response = await fetch(`${apiBaseUrl}/auth/change-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({ old_password: oldPassword, new_password: newPassword }),
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.message || t("change_password.success"));

            setSuccess("Mot de passe changé avec succès !");
            setTimeout(() => navigate("/dashboard"), 3000);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="container mx-auto mt-10 text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">{t("change_password.title")}</h1>

            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}

            <form onSubmit={handleSubmit} className="space-y-4 flex flex-col items-center">
                <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700 text-left">{t("change_password.old_password")}</label>
                    <input 
                        type="password" 
                        value={oldPassword} 
                        onChange={(e) => setOldPassword(e.target.value)} 
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm" 
                        required 
                    />
                </div>
                <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700 text-left">{t("change_password.new_password")}</label>
                    <input 
                        type="password" 
                        value={newPassword} 
                        onChange={(e) => setNewPassword(e.target.value)} 
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm" 
                        required 
                    />
                </div>
                <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700 text-left">{t("change_password.confirm_password")}</label>
                    <input 
                        type="password" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm" 
                        required 
                    />
                </div>
                <button 
                    type="submit" 
                    className="w-1/2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                >
                    {t("change_password.update")}
                </button>
            </form>
        </div>
    );
};

export default ChangePasswordPage;
