import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaCreditCard, FaPaypal, FaLock } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Payment = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount");
	const { t } = useTranslation();

    const [paymentMethod, setPaymentMethod] = useState(null);

    const handlePayment = (method) => {
        setPaymentMethod(method);
    };

    const redirectToPayPal = () => {
        window.location.href = `https://www.paypal.com/checkoutnow?amount=${amount}`;
    };

    return (
        <div className="max-w-3xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
            <h1 className="text-3xl font-bold text-gray-800 text-center">{t("payment.title")}</h1>
            <p className="text-center text-gray-600 mt-4">Vous devez payer <span className="font-semibold text-green-600">{amount}€</span> pour la création de votre événement.</p>
            
            <div className="mt-6 flex justify-center space-x-6">
                <button onClick={() => handlePayment("CB")} className="flex items-center bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-600 transition">
                    <FaCreditCard className="mr-2" /> Payer par Carte Bancaire
                </button>
                <button onClick={() => handlePayment("PayPal")} className="flex items-center bg-yellow-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-yellow-600 transition">
                    <FaPaypal className="mr-2" /> Payer par PayPal
                </button>
            </div>
            
            {paymentMethod === "CB" && (
                <div className="mt-6 p-6 border rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold text-gray-700 mb-4">Paiement par Carte Bancaire</h2>
                    <form>
                        <label className="block text-sm font-medium text-gray-700">Numéro de Carte</label>
                        <input type="text" className="w-full p-3 border rounded-md mb-4" placeholder="1234 5678 9012 3456" />
                        
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Date d'Expiration</label>
                                <input type="text" className="w-full p-3 border rounded-md" placeholder="MM/AA" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">CVV</label>
                                <input type="text" className="w-full p-3 border rounded-md" placeholder="123" />
                            </div>
                        </div>
                        
                        <button type="submit" className="mt-6 w-full bg-green-500 text-white py-3 rounded-md shadow-md hover:bg-green-600 transition flex items-center justify-center">
                            <FaLock className="mr-2" /> Valider le Paiement
                        </button>
                    </form>
                </div>
            )}
            
            {paymentMethod === "PayPal" && (
                <div className="mt-6 p-6 border rounded-lg shadow-md text-center">
                    <h2 className="text-xl font-semibold text-gray-700 mb-4">Paiement via PayPal</h2>
                    <p className="text-gray-600">Vous allez être redirigé vers PayPal pour finaliser le paiement.</p>
                    <button onClick={redirectToPayPal} className="mt-4 bg-yellow-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-yellow-600 transition">
                        Continuer vers PayPal
                    </button>
                </div>
            )}
        </div>
    );
};

export default Payment;
