import React, { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import DashboardMobile from "./DashboardMobile"; // À créer

const DashboardRedirect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  if (isMobile === null) return null; // ou spinner

  return isMobile ? <DashboardMobile /> : <Dashboard />;
};

export default DashboardRedirect;
