import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api";



const ForgotPasswordPage = () => {
	const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(null);
        setError(null);
    
        try {
            const response = await fetch(`${apiBaseUrl}/auth/forgot-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }), // ✅ Vérifie bien que l'email est inclus dans le body JSON
            });
    
            const data = await response.json();
            if (!response.ok) throw new Error(data.detail || "Une erreur est survenue.");
    
            setMessage("Un email de réinitialisation a été envoyé.");
            setTimeout(() => navigate("/login"), 5000);
        } catch (error) {
            console.error("Erreur API :", error.message);
            setError(error.message);
        }
    };

    return (
        <div className="container mx-auto mt-10 text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Mot de passe oublié</h1>
            <p className="text-gray-600 mb-6">Entrez votre email pour recevoir un lien de réinitialisation.</p>

            {message && <p className="text-green-500">{message}</p>}
            {error && <p className="text-red-500">{error}</p>}

            <form onSubmit={handleSubmit} className="space-y-4 flex flex-col items-center">
                <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700 text-left">Email</label>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm" 
                        required 
                    />
                </div>
                <button 
                    type="submit" 
                    className="w-1/2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                >
                    Envoyer un email
                </button>
            </form>
        </div>
    );
};

export default ForgotPasswordPage;
