/* global grecaptcha */
import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaCar, FaLock, FaGlobe } from "react-icons/fa"; // Import des icônes
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const CovoiturageTypeSelection = ({ eventType, setEventType }) => {
	const { t } = useTranslation();
    return (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {t("create_event.event_type")}
            </label>
            <div className="grid grid-cols-2 gap-4">
                {/* Carte pour Évènement ponctuel */}
                <div
                    onClick={() => setEventType("ponctuel")}
                    className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
                        eventType === "ponctuel"
                            ? "border-blue-500 bg-blue-100"
                            : "border-gray-300 bg-white hover:bg-gray-100"
                    }`}
                >
                    <div className="flex items-center space-x-3">
                        <FaCalendarAlt
                            className={`text-2xl ${
                                eventType === "ponctuel" ? "text-blue-500" : "text-gray-500"
                            }`}
                        />
                        <div>
                            <h3 className="text-lg font-semibold">
                                {t("create_event.one_time_event")}
                            </h3>
                            <p className="text-sm text-gray-600">
							{t("create_event.one_time_description")}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Carte pour Gestion saisonnière */}
                <div
                    onClick={() => setEventType("saisonnier")}
                    className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
                        eventType === "saisonnier"
                            ? "border-blue-500 bg-blue-100"
                            : "border-gray-300 bg-white hover:bg-gray-100"
                    }`}
                >
                    <div className="flex items-center space-x-3">
                        <FaCar
                            className={`text-2xl ${
                                eventType === "saisonnier" ? "text-blue-500" : "text-gray-500"
                            }`}
                        />
                        <div>
                            <h3 className="text-lg font-semibold">
                                {t("create_event.seasonal")}
                            </h3>
                            <p className="text-sm text-gray-600">
                                {t("create_event.seasonal_description")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PublicPrivateSelection = ({ 
  isPublic, 
  setIsPublic, 
  maxParticipants, 
  setMaxParticipants, 
  maxFreeParicipants,
  priceUnit
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {t("create_event.site_visibility")}
      </label>
      {/* Utilisation d'une grille à deux colonnes */}
      <div className="grid grid-cols-2 gap-4">
        {/* Colonne gauche : Boutons Public/Privé */}
        <div>
          <div className="grid grid-cols-2 gap-4">
            {/* Carte pour Évènement public */}
            <div
              onClick={() => setIsPublic(true)}
              className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
                isPublic
                  ? "border-green-500 bg-green-100"
                  : "border-gray-300 bg-white hover:bg-gray-100"
              }`}
            >
              <div className="flex items-center space-x-3">
                <FaGlobe className={`text-2xl ${isPublic ? "text-green-500" : "text-gray-500"}`} />
                <div>
                  <h3 className="text-lg font-semibold">{t("create_event.public")}</h3>
                  <p className="text-sm text-gray-600">
                    {t("create_event.public_description")}
                  </p>
                </div>
              </div>
            </div>
            {/* Carte pour Évènement privé */}
            <div
              onClick={() => setIsPublic(false)}
              className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
                !isPublic
                  ? "border-red-500 bg-red-100"
                  : "border-gray-300 bg-white hover:bg-gray-100"
              }`}
            >
              <div className="flex items-center space-x-3">
                <FaLock className={`text-2xl ${!isPublic ? "text-red-500" : "text-gray-500"}`} />
                <div>
                  <h3 className="text-lg font-semibold">{t("create_event.private")}</h3>
                  <p className="text-sm text-gray-600">
                    {t("create_event.private_description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};





const CreateEvent = () => {
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [suggestions, setSuggestions] = useState([]); // Suggestions pour l'autocomplétion
    const [isLoading, setIsLoading] = useState(false); // Indicateur de chargement
	const [email, setEmail] = useState(""); // Ajoutez cette ligne pour initialiser l'état email
	const [emailError, setEmailError] = useState(""); // Ajoutez cette ligne pour gérer l'erreur email
	const [eventType, setEventType] = useState("ponctuel"); // Type d'événement sélectionné
	const navigate = useNavigate(); // Utiliser pour rediriger après soumission
	const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
	const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
	const [isPublic, setIsPublic] = useState(true); // Public par défaut
	const [isEmailVisible, setIsEmailVisible] = useState(true);
	const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [isPhoneVisible, setIsPhoneVisible] = useState(false);
    const [countryCode, setCountryCode] = useState("fr"); // Par défaut France
	const [logo, setLogo] = useState(null);
	const priceUnit = Number(process.env.REACT_APP_PRICE_UNIT);
	const maxFreeParicipants = process.env.REACT_APP_MAX_FREE_PARTICIPANTS;
	const [maxParticipants, setMaxParticipants] = useState(Number(process.env.REACT_APP_MAX_FREE_PARTICIPANTS));
	const [logoPreview, setLogoPreview] = useState(null);
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	



	const handleFileChange = (event) => {
	const file = event.target.files[0];
	if (file) {
	  // Si une URL existe déjà, on la révoque pour éviter une fuite de mémoire
	  if (logoPreview) {
		URL.revokeObjectURL(logoPreview);
	  }
	  setLogo(file);
	  setLogoPreview(URL.createObjectURL(file));
	}
	};


    // Fonction pour récupérer des suggestions d'adresses via Google Places API
    const fetchSuggestions = useCallback(
        async (value) => {
            if (value.length < 3) {
                setSuggestions([]);
                return;
            }

            setIsLoading(true);

            try {
                const service = new window.google.maps.places.AutocompleteService();
                service.getPlacePredictions(
                    { input: value, types: ["geocode"], componentRestrictions: { country: "fr" } },
                    (predictions, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                            setSuggestions(
                                predictions.map((prediction) => ({
                                    place_id: prediction.place_id,
                                    description: prediction.description,
                                }))
                            );
                        } else {
                            setSuggestions([]);
                        }
                        setIsLoading(false);
                    }
                );
            } catch (error) {
                console.error("Erreur lors de la récupération des suggestions :", error);
                setSuggestions([]);
                setIsLoading(false);
            }
        },
        []
    );

    // Fonction appelée lorsqu'une saisie est effectuée dans le champ d'adresse
    const handleAddressChange = (e) => {
        const value = e.target.value;
        setAddress(value);
        fetchSuggestions(value);
    };

    // Fonction appelée lorsqu'une suggestion est sélectionnée
    const handleSuggestionSelect = async (suggestion) => {
        setAddress(suggestion.description);
        setSuggestions([]);

        // Récupérer les détails de l'adresse sélectionnée
        const geocoder = new window.google.maps.Geocoder();
        try {
            const response = await geocoder.geocode({ placeId: suggestion.place_id });
            if (response.results[0]) {
                const location = response.results[0].geometry.location;
                setLatitude(location.lat());
                setLongitude(location.lng());
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des coordonnées :", error);
        }
    };

    // Fonction pour obtenir la géolocalisation
    const getGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
    
                    setLatitude(lat);
                    setLongitude(lng);
    
                    // Utiliser le Geocoding API de Google pour récupérer l'adresse complète
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            setAddress(results[0].formatted_address); // Adresse complète
                        } else {
                            console.error("Erreur lors de la récupération de l'adresse :", status);
                            alert("Impossible de récupérer l'adresse détaillée.");
                        }
                    });
                },
                (error) => {
                    alert("Impossible d’obtenir la géolocalisation.");
                    console.error("Erreur de géolocalisation :", error.message);
                }
            );
        } else {
            alert("La géolocalisation n’est pas supportée par votre navigateur.");
        }
    };

    // Fonction de validation d'email
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (!validateEmail(value)) {
            setEmailError("Veuillez entrer une adresse email valide.");
        } else {
            setEmailError("");
        }
    };
	
    // Fonction appelée lors de la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Récupérez le fichier du logo
        const logoFile = document.getElementById("logo").files[0];

    
	    // Vérifiez les champs obligatoires
        if (!document.getElementById("title").value || !address || !latitude || !longitude) {
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }
	
        // Vérifier que grecaptcha est disponible
        if (!window.grecaptcha) {
            console.error("reCAPTCHA n'est pas encore chargé !");
            alert("reCAPTCHA n'est pas encore prêt. Veuillez réessayer.");
            return;
        }
    
        try {
            // Appeler l'API reCAPTCHA pour obtenir un token
            const token = await grecaptcha.execute(recaptchaSiteKey , { action: "submit" });
    
            // Créez un FormData pour inclure les données du formulaire et le fichier logo
            const formData = new FormData();
            formData.append("title", document.getElementById("title").value);
            formData.append("date", eventType === "ponctuel" ? document.getElementById("date").value : null);
            formData.append("time", eventType === "ponctuel" ? document.getElementById("time").value : null);
            formData.append("start_date", eventType === "saisonnier" ? document.getElementById("start_date").value : null);
            formData.append("end_date", eventType === "saisonnier" ? document.getElementById("end_date").value : null);
            formData.append("location", address);
            formData.append("latitude", latitude);
            formData.append("longitude", longitude);
            formData.append("contact", document.getElementById("contact").value);
            formData.append("event_type", eventType);
			formData.append("is_public", isPublic ? "true" : "false");
            formData.append("token", token);
            if (logoFile) {
                formData.append("logo", logoFile); // Ajout du fichier logo
            }
    
            // Envoyer les données au backend
            const response = await fetch(`${apiBaseUrl}/api/events/`, {
                method: "POST",
                body: formData, // Utilisation du FormData
            });
    
            // Lire le JSON uniquement si le statut est correct
            if (response.ok) {
                const result = await response.json();
    
                // Vérifiez si `id_user` et `id_admin` sont bien définis
                if (result.id_user && result.id_admin) {

                    const emailData = {
                        contact_email: document.getElementById("contact").value, 
                        event_title: document.getElementById("title").value,
                        public_link: `${frontendUrl}/event/user/${result.id_user}`,
                        admin_link: `${frontendUrl}/event/admin/${result.id_admin}`,
						lang: i18n.language 
                    };
                    console.log("Données envoyées au backend :", emailData);

                    const emailResponse = await fetch(`${apiBaseUrl}/api/events/send-email`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(emailData),
                    });
                    
                    const emailResult = await emailResponse.json();
                    
                    if (!emailResponse.ok) {
                        throw new Error(`Erreur lors de l'envoi de l'email : ${emailResult.detail || "Erreur inconnue"}`);
                    }

					
					// Rediriger vers la page intermédiaire de donation
					navigate(`/event/donation-proposal/${result.id_user}/${result.id_admin}`);
		
		
					//}
                } else {
                    console.error("Les identifiants sont manquants dans la réponse :", result);
                    alert("Erreur : Les identifiants (id_user et/ou id_admin) sont manquants dans la réponse.");
                }
            } else {
                const error = await response.json();
                console.error("Erreur backend : ", error);
                alert(`Erreur lors de la création de l'événement : ${error.detail || "Erreur inconnue"}`);
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi des données :", error);
            alert("Une erreur s'est produite lors de la création de l'événement.");
        }
    };
	
	useEffect(() => {
        fetch("https://ipapi.co/json/")
            .then((res) => res.json())
            .then((data) => {
                setCountryCode(data.country_code.toLowerCase());
            })
            .catch((err) => console.error("Erreur lors de la détection du pays :", err));
    }, []);

	  // Nettoyage lors du démontage du composant
	  useEffect(() => {
		return () => {
		  if (logoPreview) {
			URL.revokeObjectURL(logoPreview);
		  }
		};
	  }, [logoPreview]);


    const validatePhone = (value) => {
        if (!value.trim()) return true; // Accepter champ vide (facultatif)
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;
        return phoneRegex.test(value);
    };
	

    return (
        <div className="w-full mx-auto mt-1 p-5 bg-white shadow-lg rounded-lg">
            <form id="eventForm" onSubmit={handleSubmit} className="space-y-5">
				{/* Titre de l'Événement et Upload du Logo */}
                <div className="grid grid-cols-4 gap-4 items-center">
				{/* Upload du logo */}
				<div className="col-span-1 h-full flex items-center">
				  <div className="w-full">
					<label htmlFor="logo" className="block text-sm font-medium text-gray-700">
					  {t("create_event.upload_logo")}
					</label>
					<div className="mt-1 flex flex-col items-center justify-center border border-gray-300 rounded-md shadow-sm p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition duration-200">
					  <label htmlFor="logo" className="flex flex-col items-center cursor-pointer">
						{logo ? (
						  <div className="flex flex-col items-center">
							<img
							  src={URL.createObjectURL(logo)}
							  alt="Aperçu du logo"
							  className="w-24 h-24 object-contain"
							/>
							<p className="mt-2 text-sm text-gray-700 font-semibold">{logo.name}</p>
							<span className="mt-1 text-sm text-blue-500 underline">
							  Changer le fichier
							</span>
						  </div>
						) : (
						  <div className="flex items-center gap-2">
							<FaCloudUploadAlt className="text-blue-500 text-3xl" />
							<span className="text-sm text-gray-600">
							  {t("create_event.click_to_choose")}
							</span>
						  </div>
						)}
						<input
						  type="file"
						  id="logo"
						  name="logo"
						  accept="image/*"
						  className="hidden"
						  onChange={handleFileChange}
						/>
					  </label>
					</div>
				  </div>
				</div>
					{/* Titre du site de covoiturage */}
                    <div className="col-span-3 h-full flex items-center">
					    <div className="w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                {t("create_event.event_title")}
                            </label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                required
                                className="mt-1 block w-full p-4 border border-gray-300 rounded-md shadow-sm text-lg"
                            />
                        </div>
					</div>
                </div>
			    {/* Adresse avec autocomplétion */}
                <div className="sm:col-span-2">
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        {t("create_event.location")}
                    </label>
				<div className="flex items-center space-x-2 mt-1">
    			    <button
                            type="button"
                            onClick={getGeolocation}
                            className="mt-2 p-4 bg-blue-500 text-white rounded-full shadow hover:bg-blue-600 flex items-center justify-center"
                            title={t("create_event.use_current_location")}
                        >
                        <i className="fas fa-map-marker"></i>
                    </button>
                        <input
                            type="text"
                            id="location"
                            name="location"
				            required
                            value={address}
                            onChange={handleAddressChange} // Gestion des saisies avec débouncing
                            placeholder={t("create_event.enter_address")}
                            className="mt-1 block w-full p-4 border border-gray-300 rounded-md shadow-sm"
                        />
				</div>
                    {/* Affichage des suggestions */}
                    {isLoading ? (
                        <p className="text-sm text-gray-500 mt-2">Chargement...</p>
                    ) : (
                        suggestions.length > 0 && (
                            <ul className="border border-gray-300 bg-white mt-2 max-h-40 overflow-y-auto rounded-md shadow-lg">
                                {suggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleSuggestionSelect(suggestion)}
                                        className="p-2 cursor-pointer hover:bg-gray-100"
                                    >
                                        {suggestion.description}
                                    </li>
                                ))}
                            </ul>
                        )
                    )}
                </div>

				<div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
					<PublicPrivateSelection 
						isPublic={isPublic} 
						setIsPublic={setIsPublic} 
						maxParticipants={maxParticipants} 
						setMaxParticipants={setMaxParticipants} 
						maxFreeParicipants={maxFreeParicipants} 
						priceUnit={priceUnit}
					/>
				</div>


				{/* Type d'Événement */}
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">

                    <CovoiturageTypeSelection eventType={eventType} setEventType={setEventType} />
					

    				
                    {/* Champs conditionnels */}
                    {eventType === "ponctuel" ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 flex items-center">
                            <div>
                                <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                                    {t("create_event.event_date")}
                                </label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    required={eventType === "ponctuel"}
                                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="time" className="block text-sm font-medium text-gray-700">
                                    {t("create_event.event_time")}
                                </label>
                                <input
                                    type="time"
                                    id="time"
                                    name="time"
                                    required={eventType === "ponctuel"}
                                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 flex items-center">
                            <div>
                                <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">
                                    {t("create_event.start_date")}
                                </label>
                                <input
                                    type="date"
                                    id="start_date"
                                    name="start_date"
                                    required={eventType === "saisonnier"}
                                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="end_date" className="block text-sm font-medium text-gray-700">
                                    {t("create_event.end_date")}
                                </label>
                                <input
                                    type="date"
                                    id="end_date"
                                    name="end_date"
                                    required={eventType === "saisonnier"}
                                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm"
                                />
                            </div>
                        </div>
                    )}
                </div>


                {/* Coordonnées Géographiques */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                        <input
                            type="hidden"
                            id="latitude"
                            name="latitude"
                            value={latitude}
                            readOnly
                        />
                    </div>
                    <div>
                        <input
                            type="hidden"
                            id="longitude"
                            name="longitude"
                            value={longitude}
                            readOnly
                        />
                    </div>
                </div>

				{/* Coordonnées du Responsable */}
				<div className="grid grid-cols-2 gap-4">
				  {/* Email du Responsable */}
				  <div className="sm:col-span-1 relative">
					<label
					  htmlFor="contact"
					  className="block text-sm font-medium text-gray-700 mb-2"
					>
					  {t("create_event.contact_email")}
					</label>
					<div className="relative flex items-center h-12">
					  <input
						type="email"
						id="contact"
						name="contact"
						required
						placeholder={t("create_event.responsible_email")}
						value={email}
						onChange={handleEmailChange}
						className={`block w-full p-4 pr-12 border ${
						  emailError ? "border-red-500" : "border-gray-300"
						} rounded-md shadow-sm h-full`}
					  />
					  <button
						type="button"
						onClick={() => setIsEmailVisible(!isEmailVisible)}
						className="absolute right-0 flex items-center justify-center w-10 h-full bg-gray-200 hover:bg-gray-300 rounded-r-md text-gray-600"
						title="Afficher l'email sur votre page"
					  >
						{isEmailVisible ? (
						  <FaCheckCircle className="text-green-500" />
						) : (
						  <FaTimesCircle className="text-gray-500" />
						)}
					  </button>
					</div>
					{emailError && (
					  <p className="text-sm text-red-500 mt-1">{emailError}</p>
					)}
					<p className="text-1xl text-gray-600 mt-1">
					  {isEmailVisible
						? t("create_event.email_visible")
						: t("create_event.email_hidden")}
					</p>
				  </div>
					{/* Téléphone du Responsable */}
					<div className="sm:col-span-1 relative">
					  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">
						{t("create_event.contact_phone")}
					  </label>
					  {/* On définit ici une hauteur fixe (par exemple h-12) pour le conteneur */}
					  <div className="relative flex items-center h-12">
						<PhoneInput
						  country={countryCode}
						  value={phone}
						  onChange={(value) => {
							setPhone(value);
							if (value.trim() !== "") {
							  setPhoneError(validatePhone(value) ? "" : "Numéro invalide");
							} else {
							  setPhoneError("");
							}
						  }}
						  inputProps={{
							name: "phone",
							required: false,
							// On utilise h-full pour que l'input occupe toute la hauteur du conteneur
							className: `block w-full p-4 pl-14 pr-12 border ${
							  phoneError ? "border-red-500" : "border-gray-300"
							} rounded-md shadow-sm h-full`,
						  }}
						/>
						{/* On remplace h-full par "inset-y-0" pour forcer le bouton à occuper toute la hauteur du conteneur */}
						<button
						  type="button"
						  onClick={() => setIsPhoneVisible(!isPhoneVisible)}
						  className="absolute inset-y-0 right-0 flex items-center justify-center w-10 bg-gray-200 hover:bg-gray-300 rounded-r-md text-gray-600"
						  title="Afficher le téléphone sur votre page"
						>
						  {isPhoneVisible ? (
							<FaCheckCircle className="text-green-500" />
						  ) : (
							<FaTimesCircle className="text-gray-500" />
						  )}
						</button>
					  </div>
					  {phoneError && <p className="text-sm text-red-500 mt-1">{phoneError}</p>}
					  <p className="text-sm text-gray-600 mt-1">
						{isPhoneVisible
						  ? t("create_event.phone_visible")
						  : t("create_event.phone_hidden")}
					  </p>
					</div>
				</div>
				<br/>
                {/* Bouton Soumettre */}
                <div className="flex justify-center items-center mt-6"> {/* Centre le bouton sur la page */}
                    <button
                        type="submit"
                        className="px-6 py-3 w-2/3 bg-gradient-to-r bg-green-200 text-green-800 rounded-md hover:bg-green-300 font-semibold text-lg rounded-lg shadow-lg  transition-all duration-300 transform hover:scale-105"
                    >
                        {t("create_event.generate")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateEvent;
