import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QuiSommesNous = () => {
    const { t } = useTranslation();

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
            <h1 className="text-3xl font-bold text-green-700 mb-6">🌱 {t("about.title")}</h1>

            <p className="text-gray-700 mb-4">
                <strong>{t("about.name")}</strong> {t("about.intro")}
            </p>

            <p className="text-gray-700 mb-4">{t("about.mission")}</p>

            <h2 className="text-2xl font-semibold text-green-600 mt-6 mb-3">🚗 {t("about.why_title")}</h2>

            <p className="text-gray-700 mb-4">{t("about.why_text")}</p>

            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>{t("about.goal_1")}</li>
                <li>{t("about.goal_2")}</li>
                <li>{t("about.goal_3")}</li>
                <li>{t("about.goal_4")}</li>
            </ul>

            <h2 className="text-2xl font-semibold text-green-600 mt-6 mb-3">💚 {t("about.cost_title")}</h2>

            <p className="text-gray-700 mb-4">{t("about.cost_text_1")}</p>
            <p className="text-gray-700 mb-6">{t("about.cost_text_2")}</p>

            <div className="text-center">
                <Link
                    to="/donate"
                    className="inline-block px-6 py-3 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 transition-all"
                >
                    💸 {t("about.donate_button")}
                </Link>
            </div>
        </div>
    );
};

export default QuiSommesNous;
