import React, { useEffect, useState } from "react";
import Login from "./Login";
import LoginMobile from "./LoginMobile";

const LoginRedirect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile === null) return null; // spinner ou chargement si besoin

  return isMobile ? <LoginMobile /> : <Login />;
};

export default LoginRedirect;
