import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationFR from "./locales/fr.json";
import translationEN from "./locales/en.json";
import translationDE from "./locales/de.json";
import translationES from "./locales/es.json";

const resources = {
  fr: { translation: translationFR },
  en: { translation: translationEN },
  de: { translation: translationDE },
  es: { translation: translationES },
};

i18n
  .use(LanguageDetector)  // D�tection automatique de la langue
  .use(initReactI18next)  // ?? Activation de React-i18next
  .init({
    resources,
    fallbackLng: "en", // Langue par d�faut
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false, // Permet d'ins�rer du HTML dans les traductions
    },
  });

export default i18n;
