import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import { useTranslation } from "react-i18next";
import { countryCapitals, defaultCapital } from "../data/capitals";

const Home = () => {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [latestEvents, setLatestEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkGoogleMaps = setInterval(() => {
      if (window.google && window.google.maps) {
        clearInterval(checkGoogleMaps);
        setGoogleLoaded(true);
      }
    }, 500);
    return () => clearInterval(checkGoogleMaps);
  }, []);

  useEffect(() => {
    if (!googleLoaded) return;

    const initializeMap = async () => {
      const token = localStorage.getItem("authToken");
      let userLocation = null;

      if (token) {
        try {
          const response = await api.get("/auth/users/me");
          const { latitude, longitude } = response.data;

          if (latitude && longitude) {
            userLocation = {
              lat: latitude,
              lng: longitude,
              icon: "/icons/maison-48.png",
              title: "🏠 Mon domicile",
              zoom: 6,
            };
          }
        } catch (error) {
          console.warn("Utilisateur non connecté ou erreur de profil.");
        }
      }

      if (!userLocation) {
        try {
          const res = await fetch("https://ipapi.co/json/");
          const geo = await res.json();
          const capital = countryCapitals[geo.country_code] || defaultCapital;

          userLocation = {
            lat: capital.lat,
            lng: capital.lng,
            icon: "/icons/drapeau-48.png",
            title: `🚩 ${capital.name}`,
            zoom: 5,
          };
        } catch (error) {
          userLocation = {
            ...defaultCapital,
            icon: "/icons/drapeau-48.png",
            title: "🚩 Paris",
            zoom: 6,
          };
        }
      }

      const mapOptions = {
        center: { lat: userLocation.lat, lng: userLocation.lng },
        zoom: userLocation.zoom,
      };

      const mapInstance = new window.google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );

      new window.google.maps.Marker({
        position: { lat: userLocation.lat, lng: userLocation.lng },
        map: mapInstance,
        title: userLocation.title,
        icon: userLocation.icon,
      });

      setMap(mapInstance);
    };

    initializeMap();
  }, [googleLoaded]);


  useEffect(() => {
    if (googleLoaded) {
      fetchLatestEvents();
    }
  }, [googleLoaded]);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearch(value);
  
    if (value.trim().length < 3) {
      setSuggestions(latestEvents);
      return;
    }
  
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/events/public/find?query=${encodeURIComponent(value)}`;
      const response = await fetch(url);
      if (!response.ok) throw new Error("Erreur lors de la récupération des suggestions");
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Erreur lors de la recherche :", error);
    }
  };

  const handleSuggestionSelect = (event) => {
    window.location.href = `/event/user/${event.id_user}`;
  };

  const fetchLatestEvents = async () => {
    try {
	  const response = await api.get("/api/events/latest?limit=3");
      const data = response.data;
      setLatestEvents(data);
      setSuggestions(data); // suggestions initiales = derniers événements
    } catch (error) {
      console.error("Erreur lors de la récupération des derniers événements :", error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="w-full mx-auto flex flex-col lg:flex-row items-start justify-between py-10 px-5">
        <div
          id="map"
          className="hidden md:block h-96 w-full lg:w-1/2 border border-gray-300 rounded-md shadow-lg"
          style={{ height: "calc(100vh - 155px)" }}
        ></div>

        <div className="w-full lg:w-1/2 px-3">
          <div className="relative w-full mb-6">
            <br />
			<input
			  type="text"
			  value={search}
			  onFocus={fetchLatestEvents} // 🆕
			  onChange={handleSearchChange}
			  placeholder={t("home.search_placeholder")}
			  className="w-full p-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
			/>
            {suggestions.length > 0 && (
              <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
                {suggestions.map((event) => (
                  <li
                    key={event.id}
                    onClick={() => handleSuggestionSelect(event)}
                    className="p-4 hover:bg-gray-100 cursor-pointer"
                  >
                    <p className="font-medium text-gray-800">{event.title}</p>
                    <p className="text-sm text-gray-500">
                      {event.date
                        ? new Intl.DateTimeFormat(navigator.language, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(event.date))
                        : ""}
                      {event.start_date
                        ? new Intl.DateTimeFormat(navigator.language, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(event.start_date))
                        : ""} {" / "}
                      {event.time
                        ? new Intl.DateTimeFormat(navigator.language, {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          }).format(new Date(`${event.date}T${event.time}`))
                        : ""}
                      {event.end_date
                        ? new Intl.DateTimeFormat(navigator.language, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(event.end_date))
                        : ""}
                      - {event.location}
                    </p>
                    <input type="hidden" value={event.id_user} />
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="shadow-lg">
            <br />
            <br />
            <h1 className="text-4xl lg:text-6xl font-bold text-green-700 leading-tight text-center">
              {t("home.site_title")}
            </h1>
            <br />
            <p className="mt-6 text-lg lg:text-2xl text-gray-600 leading-relaxed text-center">
              {t("home.site_description")}
            </p>
            <br />
            <br />
            <button
              onClick={() => (window.location.href = "/event/create")}
              className="mt-8 px-10 py-4 bg-green-200 text-green-800 rounded-md hover:bg-green-300 text-3xl font-bold rounded-lg shadow transition w-full flex justify-center"
            >
              {t("home.create_event_button")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
