import React, { useState, useRef } from "react";
import { FaCloudUploadAlt, FaGlobe, FaLock, FaCalendarAlt, FaCar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const CreateEventMobile = () => {
  const titleRef = useRef(null);
  const addressRef = useRef(null);
  const emailRef = useRef(null);
  const dateRef = useRef(null);
  const startDateRef = useRef(null);
  const timeRef = useRef(null);
  const phoneRef = useRef(null);
  const endDateRef = useRef(null);
  const countryCode = Intl.DateTimeFormat().resolvedOptions().locale.slice(-2); // ex: FR, DE, CH
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    title: "",
    logo: null,
    logoPreview: null,
    is_public: true,
    address: "",
    latitude: null,
    longitude: null,
    event_type: "ponctuel",
    date: "",
    time: "",
    start_date: "",
    end_date: "",
    contact_email: "",
    show_email: false
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [invalidFields, setInvalidFields] = useState({});
  
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const val = type === "checkbox" ? checked : type === "file" ? files[0] : value;
    if (name === "logo" && files[0]) {
      const file = files[0];
      const preview = URL.createObjectURL(file);
      setForm((prev) => ({ ...prev, logo: file, logoPreview: preview }));
    } else {
      setForm((prev) => ({ ...prev, [name]: val }));
    }
	
  };

  const handleSubmit = async () => {
    setSuccess(false);
    setError("");
	setInvalidFields({});
	
  if (!form.title.trim()) {
    titleRef.current?.focus();
    setInvalidFields((prev) => ({ ...prev, title: true }));
    return;
  }
  if (!form.address || !form.latitude || !form.longitude) {
    addressRef.current?.focus();
	setInvalidFields((prev) => ({ ...prev, address: true }));
    return;
  }
  if (!form.contact_email) {
    emailRef.current?.focus();
    setInvalidFields((prev) => ({ ...prev, email: true }));
    return;
  }
  if (form.event_type === "ponctuel" && (!form.date || !form.time)) {
    dateRef.current?.focus();
	setInvalidFields((prev) => ({ ...prev, date: true }));
    return;
  }
  
  if (form.event_type === "saisonnier" && (!form.start_date || !form.end_date)) {
    startDateRef.current?.focus();
    setInvalidFields((prev) => ({ ...prev, start_date: true }));
    return;
  }
  if (form.event_type === "ponctuel" && !form.time) {
    timeRef.current?.focus();
    setInvalidFields((prev) => ({ ...prev, time: true }));
    return;
  }
  if (form.event_type === "saisonnier" && !form.end_date) {
    endDateRef.current?.focus();
    setInvalidFields((prev) => ({ ...prev, end_date: true }));
    return;
  }
  
    try {
		
  	  const cleanForm = { ...form };
  
	  if (cleanForm.event_type === "ponctuel") {
  	  cleanForm.start_date = null;
	    cleanForm.end_date = null;
	  } else if (cleanForm.event_type === "saisonnier") {
  	  cleanForm.date = null;
	    cleanForm.time = null;
	  }

      const formData = new FormData();

  	  for (const key in cleanForm) {
	    if (
  		cleanForm[key] !== null &&
		  cleanForm[key] !== undefined &&
		  cleanForm[key] !== "" &&
		  key !== "logoPreview" &&
		  key !== "contact_email"
	    ) {
  		formData.append(key, cleanForm[key]);
	    }
	  }

	  formData.append("contact", form.contact_email);
	  formData.append("location", form.address);
	  formData.append("latitude", String(parseFloat(form.latitude)));
	  formData.append("longitude", String(parseFloat(form.longitude)));
      const response = await fetch("/api/events", {
        method: "POST",
        body: formData
      });
	  
  	  const responseText = await response.text();
	  if (!response.ok) {
  	  alert(`Erreur : ${responseText}`);
	  } else {
  	    let result = {};
		try {
		  result = JSON.parse(responseText);
		} catch (err) {
		  alert("Erreur lors du traitement de la réponse serveur");
		  return;
		}
		if (result.id_user && result.id_admin) {
		  // Envoi de l'email
		  await fetch("/api/events/send-email", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
			  contact_email: form.contact_email,
			  event_title: form.title,
			  public_link: `${window.location.origin}/event/user/${result.id_user}`,
			  admin_link: `${window.location.origin}/event/admin/${result.id_admin}`,
			  lang: countryCode.toLowerCase()
			})
		  });
		  window.location.href = `/event/success/${result.id_user}/${result.id_admin}`;		  
		}
	  }
    } catch (err) {
      console.error(err);
      setError("Une erreur est survenue, veuillez réessayer.");
    }
	
    setSuccess(true);
    setForm({
  	title: "",
	  logo: null,
	  logoPreview: null,
	  is_public: true,
	  address: "",
	  latitude: null,
	  longitude: null,
	  event_type: "ponctuel",
	  date: "",
	  time: "",
	  start_date: "",
	  end_date: "",
	  contact_email: "",
	  show_email: false
    });
    setStep(1);
  
    setTimeout(() => setSuccess(false), 3000);
	
	
  };

  const handleVisibilityClick = (value) => {
    setForm((prev) => ({ ...prev, is_public: value }));
  };

  const handleTypeClick = (value) => {
    setForm((prev) => ({ ...prev, event_type: value }));
  };

  const [suggestions, setSuggestions] = useState([]);
  const getGeolocation = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition((pos) => {
      const lat = pos.coords.latitude;
      const lng = pos.coords.longitude;
      setForm((prev) => ({ ...prev, latitude: lat, longitude: lng }));
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          setForm((prev) => ({ ...prev, address: results[0].formatted_address }));
        }
      });
    });
  };
  
  const handleAddressChange = (e) => {
    const value = e.target.value;
    setForm((prev) => ({ ...prev, address: value }));
    if (!value || value.length < 3) return;
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        setSuggestions(predictions);
      }
    });
  };
  
  const handleSuggestionSelect = (suggestion) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0].geometry.location;
        setForm((prev) => ({
          ...prev,
          address: suggestion.description,
          latitude: location.lat(),
          longitude: location.lng()
        }));
        setSuggestions([]);
      }
    });
  };


return (
  <div className="max-w-xl mx-auto p-4 space-y-6">
    {success && (
      <div className="bg-green-500 text-white text-center py-3 px-4 rounded-md shadow font-semibold">
        ✅ {t("create_event.success")}
      </div>
    )}
    {error && (
      <div className="bg-red-500 text-white text-center py-3 px-4 rounded-md shadow font-semibold">
        {error}
      </div>
    )}

    {step === 1 && (
      <div className="bg-white shadow rounded-xl p-4 space-y-4">
        <h1 className="text-xl font-bold text-green-700">{t("create_event.step1")}</h1>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t("create_event.event_title")}</label>
          <input
            ref={titleRef}
            type="text"
            name="title"
            value={form.title}
            onChange={handleChange}
            className={`w-full border p-2 rounded ${invalidFields.title ? "border-orange-400" : ""}`}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">{t("create_event.upload_logo")}</label>
          <div className="flex flex-col items-center justify-center border border-gray-300 rounded-md shadow-sm p-4 bg-gray-50 cursor-pointer">
            <label htmlFor="logo" className="flex flex-col items-center cursor-pointer w-full">
              {form.logoPreview ? (
                <div className="flex flex-col items-center">
                  <img
                    src={form.logoPreview}
                    alt="Aperçu du logo"
                    className="w-24 h-24 object-contain"
                  />
                  <p className="mt-2 text-sm text-gray-700 font-semibold">{form.logo?.name}</p>
                  <span className="mt-1 text-sm text-blue-500 underline">{t("create_event.change_file")}</span>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <FaCloudUploadAlt className="text-blue-500 text-3xl" />
                  <span className="text-sm text-gray-600">{t("create_event.click_to_choose")}</span>
                </div>
              )}
              <input
                type="file"
                name="logo"
                accept="image/*"
                id="logo"
                className="hidden"
                onChange={handleChange}
              />
            </label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">{t("create_event.site_visibility")}</label>
          <div className="grid grid-cols-2 gap-4">
            <div
              onClick={() => handleVisibilityClick(true)}
              className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
                form.is_public ? "border-green-500 bg-green-100" : "border-gray-300 bg-white hover:bg-gray-100"
              }`}
            >
              <div className="flex items-center space-x-3">
                <FaGlobe className={`text-2xl ${form.is_public ? "text-green-500" : "text-gray-500"}`} />
                <div>
                  <h3 className="text-lg font-semibold">{t("create_event.public")}</h3>
                  <p className="text-sm text-gray-600">{t("create_event.public_description")}</p>
                </div>
              </div>
            </div>
            <div
              onClick={() => handleVisibilityClick(false)}
              className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
                !form.is_public ? "border-red-500 bg-red-100" : "border-gray-300 bg-white hover:bg-gray-100"
              }`}
            >
              <div className="flex items-center space-x-3">
                <FaLock className={`text-2xl ${!form.is_public ? "text-red-500" : "text-gray-500"}`} />
                <div>
                  <h3 className="text-lg font-semibold">{t("create_event.private")}</h3>
                  <p className="text-sm text-gray-600">{t("create_event.private_description")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={() => {
            if (!form.title.trim()) {
              setInvalidFields({ title: true });
              titleRef.current?.focus();
              return;
            }
            setError("");
            setStep(2);
          }}
          className="w-full bg-green-500 text-white py-2 rounded shadow hover:bg-green-600"
        >
          {t("create_event.next")}
		  </button>
        </div>
      )}

{step === 2 && (
  <div className="bg-white shadow rounded-xl p-4 space-y-4">
    <h1 className="text-xl font-bold text-green-700">{t("create_event.step2")}</h1>

    <div>
      <label className="block text-sm font-medium text-gray-700">{t("create_event.location")}</label>
      <div className="flex gap-2">
        <button
          type="button"
          onClick={getGeolocation}
          className="p-2 bg-blue-500 text-white rounded"
          title={t("create_event.use_current_location")}
        >📍</button>
        <input
          ref={addressRef}
          type="text"
          name="address"
          value={form.address}
          onChange={handleAddressChange}
          className={`flex-1 border p-2 rounded ${invalidFields.address ? "border-orange-400" : ""}`}
          placeholder={t("create_event.location")}
        />
      </div>
      {suggestions.length > 0 && (
        <ul className="border mt-2 rounded shadow bg-white max-h-40 overflow-y-auto">
          {suggestions.map((s, i) => (
            <li
              key={i}
              onClick={() => handleSuggestionSelect(s)}
              className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
            >
              {s.description}
            </li>
          ))}
        </ul>
      )}
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{t("create_event.event_type")}</label>
      <div className="grid grid-cols-2 gap-4">
        <div
          onClick={() => setForm((prev) => ({ ...prev, event_type: "ponctuel" }))}
          className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
            form.event_type === "ponctuel" ? "border-blue-500 bg-blue-100" : "border-gray-300 bg-white hover:bg-gray-100"
          }`}
        >
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold">{t("create_event.one_time")}</h3>
            <p className="text-sm text-gray-600">{t("create_event.one_time_description")}</p>
          </div>
        </div>
        <div
          onClick={() => setForm((prev) => ({ ...prev, event_type: "saisonnier" }))}
          className={`p-4 border rounded-lg cursor-pointer transition-all duration-300 shadow-md ${
            form.event_type === "saisonnier" ? "border-blue-500 bg-blue-100" : "border-gray-300 bg-white hover:bg-gray-100"
          }`}
        >
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold">{t("create_event.seasonal")}</h3>
            <p className="text-sm text-gray-600">{t("create_event.seasonal_description")}</p>
          </div>
        </div>
      </div>
    </div>

    {form.event_type === "ponctuel" && (
      <>
        <div>
          <label className="block text-sm font-medium text-gray-700">{t("create_event.event_date")}</label>
          <input
            ref={dateRef}
            type="date"
            name="date"
            value={form.date}
            onChange={handleChange}
            className={`w-full border p-2 rounded ${invalidFields.date ? "border-orange-400" : ""}`}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">{t("create_event.event_time")}</label>
          <input
            ref={timeRef}
            type="time"
            name="time"
            value={form.time}
            onChange={handleChange}
            className={`w-full border p-2 rounded ${invalidFields.time ? "border-orange-400" : ""}`}
          />
        </div>
      </>
    )}

    {form.event_type === "saisonnier" && (
      <>
        <div>
          <label className="block text-sm font-medium text-gray-700">{t("create_event.start_date")}</label>
          <input
            ref={startDateRef}
            type="date"
            name="start_date"
            value={form.start_date}
            onChange={handleChange}
            className={`w-full border p-2 rounded ${invalidFields.start_date ? "border-orange-400" : ""}`}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">{t("create_event.end_date")}</label>
          <input
            ref={endDateRef}
            type="date"
            name="end_date"
            value={form.end_date}
            onChange={handleChange}
            className={`w-full border p-2 rounded ${invalidFields.end_date ? "border-orange-400" : ""}`}
          />
        </div>
      </>
    )}

    <div>
      <label className="block text-sm font-medium text-gray-700">{t("create_event.contact_email")}</label>
      <input
        ref={emailRef}
        type="email"
        name="contact_email"
        value={form.contact_email}
        onChange={handleChange}
        className={`w-full border p-2 rounded ${invalidFields.email ? "border-orange-400" : ""}`}
        placeholder="exemple@mail.com"
      />
      <div className="mt-1">
        <label className="text-sm text-gray-600">
          <input
            type="checkbox"
            name="show_email"
            checked={form.show_email}
            onChange={handleChange}
            className="mr-2"
          />
          {t("create_event.email_visible_short")}
        </label>
      </div>
    </div>

    <div className="flex justify-between items-center pt-2">
      <button
        type="button"
        onClick={() => setStep(1)}
        className="text-blue-500 hover:underline text-sm bg-gray-100 px-4 py-2 rounded shadow hover:bg-gray-200 transition"
      >
        ⬅ {t("create_event.back_step1")}
      </button>
      <button
        type="button"
        onClick={handleSubmit}
        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        {t("create_event.generate")}
      </button>
    </div>
  </div>
)}


    </div>
  );
};

export default CreateEventMobile;