import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, CircleMarker, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@fortawesome/fontawesome-free/css/all.min.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import api from "../../api/api";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000"; // Récupérer la variable d'env dans React

// Corriger le chemin des icônes Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

	
const RecenterMap = ({ position }) => {
    const map = useMap();

    useEffect(() => {
        if (position) {
            map.setView(position, 13, { animate: true });
        }
    }, [position, map]);

    return null;
	};

const EventMap = ({ position, markers, userParticipant, eventLocation, onMarkerClick, user, selectedEvent, refreshMatchRequests }) => {
	const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
	const [showContactModal, setShowContactModal] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [message, setMessage] = useState("");
	const [loadingEmail, setLoadingEmail] = useState(false);
    const [sentSuccess, setSentSuccess] = useState(false);

	
	if (!user) {
		console.log("Utilisateur non connecté");
		return null;
	}

	const isValidLatLng = Array.isArray(position) && position.length === 2 && position.every(coord => typeof coord === "number" && !isNaN(coord));
	const safePosition = isValidLatLng ? position : [48.8566, 2.3522]; // Paris par défaut

	if (!isValidLatLng) {
		console.warn("⚠️ Position invalide pour Leaflet :", position);
	}
	
    const handleContactClick = (participant) => {
		console.log("Participant sélectionné:", participant);
        setSelectedParticipant(participant);
        setSentSuccess(false);
        setLoadingEmail(false);
        setShowContactModal(true);
    };

	const handleSendEmail = async () => {
	  setLoadingEmail(true);
	  try {
		if (!selectedParticipant || !selectedEvent) {
		  alert("Données manquantes pour envoyer l'email.");
		  setLoadingEmail(false);
		  return;
		}

		const currentParticipant = selectedEvent.participants?.find(p => p.email === user.email);
		if (!currentParticipant) {
		  alert("Erreur : impossible de trouver votre profil de participant.");
		  setLoadingEmail(false);
		  return;
		}

		console.log("📧 [API] Envoi mail à :", selectedParticipant.email);
		console.log("📧 [API] sender_name :", currentParticipant.name);

		const emailRes = await api.post("/api/participants/send_email_contact", {
		  to_email: selectedParticipant.email,
		  sender_name: currentParticipant.name,
		  event_title: selectedEvent.title,
		  message
		});

		console.log("✅ [API] Mail envoyé :", emailRes.data);

		// Match request
		try {
		  await api.post("/api/match-requests/create", {
			sender_id: currentParticipant.id,
			receiver_id: selectedParticipant.id,
			event_id: selectedEvent.id,
			status: "private"
		  });
		  console.log("✅ Match request créée");
		} catch (error) {
		  if (error?.response?.status === 400) {
			console.log("⚠️ Match request déjà existante, c'est normal.");
		  } else {
			console.error("❌ Erreur inattendue match-request :", error);
			// ❌ on NE relance plus pour ne pas faire planter handleSendEmail
		  }
		}

		refreshMatchRequests();

		setSentSuccess(true);
		setMessage("");
		setTimeout(() => {
		  setShowContactModal(false);
		  setSentSuccess(false);
		}, 1500);
	  } catch (error) {
		console.error("❌ [handleSendEmail] Exception complète :", error);
		alert("Une erreur s'est produite. Veuillez réessayer.");
	  } finally {
		setLoadingEmail(false);
	  }
	};
	
	const handleNotificationConfirmation = (marker) => {
		setSelectedMarker(marker);
		setShowConfirmModal(true);
	};
	
	if (!user) {
		console.error("❌ Erreur : `user` n'est pas défini dans EventMap !");
	}
	
	if (!onMarkerClick) {
        console.error("❌ onMarkerClick n'est pas défini !");
    }
	
    const defaultPosition = position || [48.8566, 2.3522]; // Paris par défaut si aucune position donnée
	
    const homeIcon = new L.Icon({
        iconUrl: "/icons/maison-48.png",
        shadowUrl: markerShadow, // Ajout d'une ombre pour éviter un bug d'affichage
        iconSize: [32, 32],
        iconAnchor: [16, 32]
    });
    
    const flagIcon = new L.Icon({
        iconUrl: "/icons/drapeau-48.png",
        shadowUrl: markerShadow, 
        iconSize: [32, 32],
        iconAnchor: [16, 32]
    });

    const autreIcon = new L.Icon({
        iconUrl: "/icons/autre-48.png",
        shadowUrl: markerShadow, 
        iconSize: [32, 32],
        iconAnchor: [16, 32]
    });
	
	
	{userParticipant && 
		<Marker position={[userParticipant.latitude, userParticipant.longitude]} icon={homeIcon}>
			<Popup>🏠 Mon domicile</Popup>
		</Marker>
	}


	{eventLocation?.latitude && eventLocation?.longitude && (
		<Marker position={[eventLocation.latitude, eventLocation.longitude]} icon={flagIcon}>
			<Popup>🚩 Lieu de l'événement</Popup>
		</Marker>
	)}

	const confirmSendNotification = async () => {
		if (!selectedMarker || !userParticipant || !selectedEvent) {
			alert("Erreur : Données manquantes pour envoyer la notification.");
			return;
		}

		const requestData = {
			sender_id: userParticipant.id,
			receiver_id: selectedMarker.id,
			event_id: selectedEvent.id,
			status: "pending"
		};

		console.log("📡 Envoi des données à l'API :", requestData);



		try {
			const response = await api.post("/api/match-requests/create", requestData, {
				headers: { "Content-Type": "application/json" }
			});

			if (response.status === 200) {
				refreshMatchRequests(); // ✅ Rafraîchir les toolbars après l'envoi
			} else {
				throw new Error("Erreur lors de l'envoi de la demande.");
			}
		} catch (error) {
			console.error("❌ Erreur lors de l'envoi de la notification :", error);
		}

		setShowConfirmModal(false); // ✅ Fermer le modal après l'envoi
	};


    return (
		<>
		<MapContainer  center={safePosition} zoom={13} style={{ height: "100%", width: "100%" }}>
			<RecenterMap position={safePosition} />
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			/>
			console.log("📍 markers:", markers);
			{markers &&
				markers.map((marker, index) => {
					const isUserMarker = userParticipant && marker.email === userParticipant.email;
					const isEventMarker = eventLocation && marker.position[0] === eventLocation.latitude && marker.position[1] === eventLocation.longitude;

					return (
						<Marker 
							key={index} 
							position={marker.position} 
							icon={isUserMarker ? homeIcon : isEventMarker ? flagIcon : autreIcon}
							eventHandlers={{
								mouseover: (e) => {
									e.target.openPopup();
									clearTimeout(e.target.closeTimeout);
								},
								mouseout: (e) => {
									e.target.closeTimeout = setTimeout(() => {
										e.target.closePopup();
									}, 2000);
								}
							}}
						>
							<Popup>
        {isUserMarker ? (
          "🏠 Mon domicile"
        ) : isEventMarker ? (
          <strong>🚩 {marker.name}</strong>
        ) : (
          <>
            <p><strong>Nom :</strong> {marker.name}</p>
            <p><strong>Fumeur ?</strong> {marker.fumeur || "Non précisé"}</p>
            <p><strong>Préférences :</strong> {marker.chauffeur || "Non précisé"}</p>
            <p><strong>Places disponibles :</strong> {marker.places || "Non précisé"}</p>
            <p><strong>Commentaire :</strong> {marker.comment || "Aucun"}</p>
            {selectedEvent && !selectedEvent.is_public ? (
              <button 
                onClick={() => handleContactClick(marker)} 
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full"
              >
                Contactez-moi
              </button>
            ) : (
			<button 
			  onClick={() => handleContactClick(marker)} 
			  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full"
			>
			  Contacter
			</button>
            )}
          </>
        )}
      </Popup>
						</Marker>
					);
				})}
		</MapContainer>
		{showContactModal && (
			<div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                <div className="bg-white rounded-lg shadow-lg p-8 w-[600px] max-w-2xl">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6">Contacter {selectedParticipant?.name}</h2>
					<p><strong>Événement :</strong> {selectedEvent?.title}</p>
					<p><strong>Date: </strong>
						{selectedEvent?.date && selectedEvent.time ? (
							<span className="text-gray-500">
								{new Date(selectedEvent.date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })} à {new Date(`1970-01-01T${selectedEvent.time}`).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
							</span>
						) : selectedEvent.start_date && selectedEvent.end_date ? (
							<span className="text-gray-500">
								Du {new Date(selectedEvent.start_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })} au {new Date(selectedEvent.end_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
							</span>
						) : (
							<span className="text-gray-500">Date non spécifiée</span>
						)}
					</p>
					<p><strong>Lieu :</strong><span className="text-gray-500"> {selectedEvent?.location} </span></p>
					{loadingEmail ? <p className="text-gray-500 mb-4 text-center">⏳ Veuillez patienter, envoi en cours...</p> : sentSuccess ? <p className="text-green-600 mb-4 text-center">✅ Message envoyé avec succès.</p> : null}
<textarea
						className="w-full p-3 border rounded-md mt-6 h-40 resize-none"
						placeholder="Votre message..."
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<div className="flex justify-end space-x-4 mt-6">
						<button onClick={() => setShowContactModal(false)} className="px-5 py-3 text-gray-600 border border-gray-400 rounded-md hover:bg-gray-100 transition-all">
							Annuler
						</button>
						<button
						  onClick={handleSendEmail}
						  disabled={loadingEmail || sentSuccess}
						  className={`px-5 py-3 rounded-md transition-all ${
							loadingEmail ? "bg-gray-400 cursor-not-allowed" :
							sentSuccess ? "bg-green-500 text-white" :
							"bg-blue-500 text-white hover:bg-blue-600"
						  }`}
						>
						  {loadingEmail ? "Envoi..." : sentSuccess ? "Envoyé ✅" : "Envoyer"}
						</button>
					</div>
				</div>
			</div>
		)}
		{showConfirmModal && (
		  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
			<div className="bg-white rounded-lg shadow-lg p-6 w-[600px]">
			  <h2 className="text-xl font-bold text-gray-800 mb-4">Contacter {selectedMarker?.name}</h2>
			  <p className="text-gray-600 mb-4">Vous pouvez lui envoyer un message ci-dessous :</p>

			  {loadingEmail ? <p className="text-gray-500 mb-4 text-center">⏳ Veuillez patienter, envoi en cours...</p> : sentSuccess ? <p className="text-green-600 mb-4 text-center">✅ Message envoyé avec succès.</p> : null}
<textarea
				className="w-full p-3 border rounded-md h-40 resize-none"
				placeholder="Votre message..."
				value={message}
				onChange={(e) => setMessage(e.target.value)}
			  />

			  <div className="flex justify-end space-x-4 mt-4">
				<button
				  onClick={() => setShowConfirmModal(false)}
				  className="px-4 py-2 text-gray-600 border border-gray-400 rounded-md hover:bg-gray-100 transition-all"
				>
				  Annuler
				</button>
				<button
				  onClick={confirmSendNotification}
				  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-all"
				>
				  Envoyer
				</button>
			  </div>
			</div>
		  </div>
		)}
		</>
    );
};

export default EventMap;
