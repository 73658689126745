import axios from "axios";

// 🔹 Création de l'instance Axios avec baseURL et authentification
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8000",
    headers: {
        "Content-Type": "application/json",
    }
});

// 🔹 Intercepteur pour inclure le token JWT dans chaque requête
api.interceptors.request.use((config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// 🔹 Intercepteur pour gérer la déconnexion automatique si le token expire
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("authToken"); // Supprimer le token si expiré
            window.location.href = "/login"; // Rediriger vers la connexion
        }
        return Promise.reject(error);
    }
);

export default api;
