import React, { useState } from "react";
import { FaGoogle, FaFacebook, FaMicrosoft, FaApple } from "react-icons/fa";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignupPage = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [acceptCgu, setAcceptCgu] = useState(false);
  const [showCguError, setShowCguError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOAuthSignup = (provider) => {
    window.location.href = `${apiBaseUrl}/auth/${provider}`;
  };

  const handleSubmit = async (e) => {
    if (!acceptCgu) {
      setShowCguError(true);
      return;
    }

    e.preventDefault();
    setError(null);
    setSuccess(null);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(t("signup.invalid_email"));
      return;
    }

    if (password.length < 8 || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
      setError(t("signup.password_requirement"));
      return;
    }

    if (fullName.trim() === "") {
      setError(t("signup.full_name_required"));
      return;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password, full_name: fullName })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.detail || "Une erreur est survenue.");
      setSuccess(t("signup.email_verification"));
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="container mx-auto mt-10 text-center">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">{t("signup.title")}</h1>
      <p className="text-gray-600 mb-6">
        {t("signup.already_have_account")}{" "}
        <a href="/Dashboard" className="text-blue-600 hover:underline">Connexion</a>
      </p>

      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
      <br />

      <form onSubmit={handleSubmit} className="space-y-4 flex flex-col items-center">
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 text-left">{t("signup.email")}</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="mt-1 block w-full p-2 border rounded-md shadow-sm" required />
        </div>
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 text-left">{t("signup.password")}</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full p-2 border rounded-md shadow-sm pr-10"
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 text-left">{t("signup.full_name")}</label>
          <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} className="mt-1 block w-full p-2 border rounded-md shadow-sm" required />
        </div>
        <div className="flex items-center space-x-2 mt-4">
          <input
            type="checkbox"
            id="acceptCgu"
            checked={acceptCgu}
            onChange={(e) => {
              setAcceptCgu(e.target.checked);
              setShowCguError(false);
            }}
            required
          />
          <label htmlFor="acceptCgu" className="text-base font-medium text-gray-700">
            J’ai lu et j’accepte les{" "}
            <a
              href="/cgu"
              className="text-blue-600 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Conditions Générales d'Utilisation
            </a>
          </label>
        </div>
        {showCguError && (
          <p className="text-red-600 text-sm mt-1">✅ Vous devez accepter les CGU pour continuer.</p>
        )}

        <button
          type="submit"
          disabled={!acceptCgu}
          className={`w-1/2 px-4 py-2 text-white rounded-md transition-colors ${
            acceptCgu ? "bg-green-500 hover:bg-green-600" : "bg-gray-400 cursor-not-allowed"
          }`}
        >
          {t("signup.signup_button")}
        </button>
      </form>

      <div className="my-2 text-center">ou</div>
      <br />

      <div className="space-y-4 flex flex-col items-center">
        <button
          onClick={() => handleOAuthSignup("google")}
          className="w-1/2 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md transition-colors hover:bg-green-100"
        >
          <FaGoogle className="text-red-500 mr-2" /> {t("signup.oauth_google")}
        </button>
        <button
          onClick={() => handleOAuthSignup("facebook")}
          className="w-1/2 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md transition-colors hover:bg-green-100"
        >
          <FaFacebook className="text-blue-600 mr-2" /> {t("signup.oauth_facebook")}
        </button>
      </div>
    </div>
  );
};

export default SignupPage;
