import React, { useEffect, useState } from "react";
import Signup from "./Signup";
import SignupMobile from "./SignupMobile";

const SignupRedirect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const checkScreen = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkScreen();
    window.addEventListener("resize", checkScreen);
    return () => window.removeEventListener("resize", checkScreen);
  }, []);

  if (isMobile === null) return null; // spinner ou chargement possible ici

  return isMobile ? <SignupMobile /> : <Signup />;
};

export default SignupRedirect;