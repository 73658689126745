import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash, FaGoogle, FaFacebook } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../api/api";

const LoginMobile = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem("authToken", token);
      window.dispatchEvent(new Event("storage"));

      const isNew = urlParams.get("new_user") === "true";
      const destination = isNew ? "/profil" : "/dashboard";

      navigate(destination, { replace: true });
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await api.post("/auth/login", { email, password });
      if (response.data?.access_token) {
        localStorage.setItem("authToken", response.data.access_token);
        window.dispatchEvent(new Event("storage"));
        navigate("/dashboard", { replace: true });
      }
    } catch (err) {
      setError(err.response?.data?.detail || "Email ou mot de passe incorrect.");
    }
  };

  const handleOAuth = (provider) => {
    window.location.href = `https://eventbycar.com/backend/auth/${provider}`;
  };

  return (
    <div className="px-4 pt-8 pb-16 text-left">
      <h1 className="text-2xl font-bold text-center mb-6">Connexion</h1>
      {error && <p className="text-red-500 text-sm text-center mb-4">{error}</p>}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded-md"
            required
          />
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">Mot de passe</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border rounded-md pr-10"
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
        >
          Se connecter
        </button>

        <div className="text-center mt-4">
          <a href="/forgot-password" className="text-blue-600 hover:underline text-sm">
            Mot de passe oublié ?
          </a>
        </div>
      </form>

      <div className="text-center my-6 text-gray-500">ou</div>

      <div className="space-y-3">
        <button
          onClick={() => handleOAuth("google")}
          className="w-full flex items-center justify-center px-4 py-2 border rounded-md hover:bg-green-100"
        >
          <FaGoogle className="mr-2 text-red-500" /> Se connecter avec Google
        </button>
        <button
          onClick={() => handleOAuth("facebook")}
          className="w-full flex items-center justify-center px-4 py-2 border rounded-md hover:bg-green-100"
        >
          <FaFacebook className="mr-2 text-blue-600" /> Se connecter avec Facebook
        </button>
      </div>
    </div>
  );
};

export default LoginMobile;