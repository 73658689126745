import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api";

const ResetPasswordPage = () => {
	const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    // 🔹 Récupérer le token de l'URL
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get("token");  // 🔹 Extrait seulement le token JWT
    
        console.log("🔑 Token récupéré depuis l'URL :", tokenParam);  // ✅ Vérifie si c'est bien le token
    
        if (tokenParam) {
            setToken(tokenParam);
        } else {
            setError("Lien de réinitialisation invalide ou expiré.");
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
    
        console.log("📤 Envoi des données :", {
            token,
            new_password: newPassword
        });
    
        try {
            const response = await fetch(`${apiBaseUrl}/auth/reset-password`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    token,
                    new_password: newPassword
                }),
            });
    
            const data = await response.json();
            console.log("📩 Réponse API :", data);
    
            if (!response.ok) throw new Error(data.detail || "Erreur lors de la réinitialisation.");
    
            setSuccess("Votre mot de passe a été réinitialisé avec succès !");
            setTimeout(() => navigate("/login"), 3000);
        } catch (error) {
            console.error("Erreur API :", error.message);
            if (Array.isArray(error.response?.data?.detail)) {
                setError(error.response.data.detail.map((err) => err.msg).join(", "));
            } else {
                setError(error.response?.data?.detail || "Une erreur est survenue.");
            }
        }
    };

    return (
        <div className="container mx-auto mt-10 text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Réinitialisation du Mot de Passe</h1>
            <p className="text-gray-600 mb-6">Choisissez un nouveau mot de passe.</p>

            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}

            <form onSubmit={handleSubmit} className="space-y-4 flex flex-col items-center">
                <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700 text-left">Nouveau mot de passe</label>
                    <input 
                        type="password" 
                        value={newPassword} 
                        onChange={(e) => setNewPassword(e.target.value)} 
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm" 
                        required 
                    />
                </div>
                <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700 text-left">Confirmez le mot de passe</label>
                    <input 
                        type="password" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm" 
                        required 
                    />
                </div>
                <button 
                    type="submit" 
                    className="w-1/2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                >
                    Mettre à jour
                </button>
            </form>
        </div>
    );
};

export default ResetPasswordPage;
