import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EventSuccess = () => {
  const { t } = useTranslation();
  const { id_user, id_admin } = useParams(); // Récupère les IDs depuis l'URL
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

  // Fonction pour copier le texte dans le presse-papiers
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Optionnel : notifier l'utilisateur
    }).catch((err) => {
      console.error("Erreur lors de la copie dans le presse-papiers : ", err);
    });
  };

  return (
    <div className="container mx-auto mt-10 p-5 bg-white shadow-lg rounded-lg space-y-8">
      <h1 className="text-3xl font-bold text-gray-800">
        {t("event_success.title")}
      </h1>

      <div className="space-y-6">
        {/* Section Lien Utilisateur */}
        <div className="bg-gray-100 p-4 rounded border border-gray-300">
          <h2 className="text-2xl font-semibold mb-2">{t("event_success.user_link_title")}</h2>
          <div className="flex items-center">
            <input
              type="text"
              value={`${frontendUrl}/event/user/${id_user}`}
              readOnly
              className="w-[40rem] bg-transparent outline-none border border-gray-200 p-3 rounded"
            />
            <button
              onClick={() => copyToClipboard(`${frontendUrl}/event/user/${id_user}`)}
              title={t("event_success.copy_url")}
              className="ml-2 text-blue-500"
            >
              <i className="fas fa-copy"></i>
            </button>
		    </div>
			<p className="text-green-600 text-md mt-2">
			    {t("event_success.user_link_info")}
			</p>

        </div>

        {/* Section Lien Administrateur */}
        <div className="bg-gray-100 p-4 rounded border border-gray-300">
          <h2 className="text-2xl font-semibold mb-2">{t("event_success.admin_link_title")}</h2>
          <div className="flex items-center">
            <input
              type="text"
              value={`${frontendUrl}/event/admin/${id_admin}`}
              readOnly
              className="w-[40rem] bg-transparent outline-none border border-gray-200 p-3 rounded"
            />
            <button
              onClick={() => copyToClipboard(`${frontendUrl}/event/admin/${id_admin}`)}
              title={t("event_success.copy_url")}
              className="ml-2 text-blue-500"
            >
              <i className="fas fa-copy"></i>
            </button>
          </div>
          <p className="text-red-600 text-md mt-2">
            {t("event_success.admin_link_warning")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventSuccess;
