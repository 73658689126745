import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    subject: "bug",
    message: "",
  });

  const [emailError, setEmailError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // Vérification du format email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));

    if (name === "email") {
      setEmailError(validateEmail(value) ? "" : "Email invalide");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError("");

    if (!validateEmail(form.email)) {
      setEmailError("Email invalide");
      return;
    }

    try {
	    const response = await fetch(`${apiBaseUrl}/api/contacts/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      console.log("✅ Status de réponse API :", response.status); 
      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi");
      }

      setSubmitted(true);
    } catch (error) {
      console.error("❌ Erreur :", error);
      setSubmitError("Une erreur est survenue. Merci de réessayer plus tard.");
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 bg-white p-6 rounded shadow-md">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        {t("contact.title")}
      </h1>

      {submitted ? (
        <div className="text-green-600 font-semibold text-center">
          {t("contact.confirmation")}
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              {t("contact.name")}
            </label>
            <input
              type="text"
              name="fullName"
              required
              className="w-full p-2 border border-gray-300 rounded"
              onChange={handleChange}
              value={form.fullName}
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-1">
              {t("contact.email")}
            </label>
            <input
              type="email"
              name="email"
              required
              className={`w-full p-2 border rounded ${emailError ? "border-red-500" : "border-gray-300"}`}
              onChange={handleChange}
              value={form.email}
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">{emailError}</p>
            )}
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-1">
              {t("contact.subject")}
            </label>
            <select
              name="subject"
              value={form.subject}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="bug">{t("contact.options.bug")}</option>
              <option value="help">{t("contact.options.help")}</option>
              <option value="feature">{t("contact.options.feature")}</option>
              <option value="info">{t("contact.options.info")}</option>
              <option value="other">{t("contact.options.other")}</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-1">
              {t("contact.message")}
            </label>
            <textarea
              name="message"
              required
              rows={5}
              className="w-full p-2 border border-gray-300 rounded"
              onChange={handleChange}
              value={form.message}
            ></textarea>
          </div>

          {submitError && (
            <p className="text-red-500 text-center font-semibold">
              {submitError}
            </p>
          )}

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition"
          >
            {t("contact.send")}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactUs;
