import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

const EventPageMobile = () => {
  const { id_user } = useParams();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    name: "",
    email: "",
    address: "",
    latitude: null,
    longitude: null,
    fumeur: "",
    chauffeur: "",
    comment: ""
  });
  const [suggestions, setSuggestions] = useState([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const res = await fetch(`${apiBaseUrl}/api/events/user/${id_user}`);
        const data = await res.json();
        setEventData(data);
      } catch (err) {
        console.error("Erreur lors du chargement de l'événement:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchEvent();
  }, [id_user, apiBaseUrl]);

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setForm((prev) => ({ ...prev, latitude: lat, longitude: lng }));

          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK" && results[0]) {
              setForm((prev) => ({
                ...prev,
                address: results[0].formatted_address,
              }));
            }
          });
        },
        (error) => {
          console.error("Erreur géolocalisation:", error);
        }
      );
    }
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setForm((prev) => ({ ...prev, address: value }));
    if (value.length < 3) return;

    setIsLoadingSuggestions(true);
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: value, types: ["geocode"] },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(
            predictions.map((p) => ({ description: p.description, placeId: p.place_id }))
          );
        } else {
          setSuggestions([]);
        }
        setIsLoadingSuggestions(false);
      }
    );
  };

  const handleSuggestionSelect = async (suggestion) => {
    setForm((prev) => ({ ...prev, address: suggestion.description }));
    setSuggestions([]);

    const geocoder = new window.google.maps.Geocoder();
    try {
      const result = await geocoder.geocode({ placeId: suggestion.placeId });
      if (result.results[0]) {
        const location = result.results[0].geometry.location;
        setForm((prev) => ({
          ...prev,
          latitude: location.lat(),
          longitude: location.lng(),
        }));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des coordonnées:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError("");

    if (!form.name || !form.email || !form.address) {
      setError("Tous les champs sont obligatoires.");
      return;
    }

    try {
      const token = await window.grecaptcha.execute(recaptchaSiteKey, { action: "submit" });

      const res = await fetch(`${apiBaseUrl}/api/participants`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...form,
          event_id: eventData.id,
          token
        })
      });

      if (!res.ok) {
        const msg = await res.json();
        if (msg.detail && msg.detail.includes("duplicate")) {
          setError("❌ Vous avez déjà une demande pour cet événement.");
        } else {
          throw new Error("Erreur inconnue");
        }
        return;
      }

      setSuccess(true);
      setForm({
        name: "",
        email: "",
        address: "",
        latitude: null,
        longitude: null,
        fumeur: "",
        chauffeur: "",
        comment: ""
      });
      setTimeout(() => setSuccess(false), 3000);

    } catch (err) {
      console.error(err);
      setError("Une erreur est survenue. Veuillez réessayer.");
    }
  };

  if (loading) return <p className="text-center mt-10 text-gray-500">Chargement...</p>;
  if (!eventData) return <p className="text-center text-red-500 mt-10">Erreur lors du chargement</p>;

  return (
    <div className="p-4 space-y-6 max-w-xl mx-auto">
      {success && (
        <div className="bg-green-500 text-white text-center py-3 px-4 rounded-md shadow font-semibold">
          ✅ Votre demande de covoiturage a été envoyée avec succès !
        </div>
      )}
      {error && (
        <div className="bg-red-500 text-white text-center py-3 px-4 rounded-md shadow font-semibold">
          {error}
        </div>
      )}

      <div className="bg-white rounded-xl shadow p-4">
        <h1 className="text-2xl font-bold text-green-700 mb-1">{eventData.title}</h1>
        <p className="text-sm text-gray-600 mb-1">📍 {eventData.location}</p>
        {eventData.date && (
          <p className="text-sm text-gray-500">
            📅 {new Intl.DateTimeFormat(navigator.language, {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(new Date(eventData.date))}
          </p>
        )}
      </div>

      <form className="bg-white shadow rounded-xl p-4 space-y-4" onSubmit={handleSubmit}>
        <div>
          <label className="block text-sm text-gray-700 font-medium mb-1">Nom</label>
          <input
            type="text"
            value={form.name}
            onChange={(e) => setForm((prev) => ({ ...prev, name: e.target.value }))}
            className="w-full border border-gray-300 p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400"
            placeholder="Votre nom"
          />
        </div>
        <div>
          <label className="block text-sm text-gray-700 font-medium mb-1">Email</label>
          <input
            type="email"
            value={form.email}
            onChange={(e) => setForm((prev) => ({ ...prev, email: e.target.value }))}
            className="w-full border border-gray-300 p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400"
            placeholder="Votre adresse email"
          />
        </div>
        <div>
          <label className="block text-sm text-gray-700 font-medium mb-1">Adresse de départ</label>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={getGeolocation}
              className="p-3 bg-blue-500 text-white rounded-full hover:bg-blue-600"
              title="Utiliser ma position actuelle"
            >📍</button>
            <input
              type="text"
              value={form.address}
              onChange={handleAddressChange}
              placeholder="Tapez votre adresse"
              className="flex-1 border border-gray-300 p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400"
            />
          </div>
          {isLoadingSuggestions && <p className="text-sm text-gray-500 mt-1">Chargement...</p>}
          {suggestions.length > 0 && (
            <ul className="border mt-2 rounded shadow max-h-40 overflow-y-auto bg-white">
              {suggestions.map((s, i) => (
                <li
                  key={i}
                  onClick={() => handleSuggestionSelect(s)}
                  className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
                >
                  {s.description}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Êtes-vous fumeur ?</label>
          <select
            value={form.fumeur}
            onChange={(e) => setForm((prev) => ({ ...prev, fumeur: e.target.value }))}
            className="w-full border border-gray-300 p-3 rounded-md shadow-sm"
          >
            <option value="">-- Choisir --</option>
            <option value="Oui">Oui</option>
            <option value="Non">Non</option>
            <option value="Pas en voiture">Pas en voiture</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Préférences de trajet</label>
          <select
            value={form.chauffeur}
            onChange={(e) => setForm((prev) => ({ ...prev, chauffeur: e.target.value }))}
            className="w-full border border-gray-300 p-3 rounded-md shadow-sm"
          >
            <option value="">-- Choisir --</option>
            <option value="Je préfère prendre ma voiture">Je préfère prendre ma voiture</option>
            <option value="Je préfère me faire conduire">Je préfère me faire conduire</option>
            <option value="Je n'ai pas de préférence">Je n'ai pas de préférence</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Commentaire</label>
          <textarea
            value={form.comment}
            onChange={(e) => setForm((prev) => ({ ...prev, comment: e.target.value }))}
            className="w-full border border-gray-300 p-3 rounded-md shadow-sm"
            rows="3"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-green-500 text-white py-3 rounded-md font-semibold text-lg shadow hover:bg-green-600 transition"
        >
          Je veux covoiturer !
        </button>
      </form>

      {form.latitude && form.longitude && (
        <div className="rounded-xl overflow-hidden shadow-md">
          <iframe
            title="Carte"
            width="100%"
            height="250"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${form.latitude},${form.longitude}`}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default EventPageMobile;
