/* global grecaptcha */
/* global google */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { useNavigate } from "react-router-dom";
import L from "leaflet";

const flagIcon = new L.Icon({
    iconUrl: "/icons/drapeau-48.png",
    shadowUrl: markerShadow,
    iconSize: [32, 32],
    iconAnchor: [16, 32]
});

const homeIcon = new L.Icon({
    iconUrl: "/icons/maison-48.png",
    shadowUrl: markerShadow,
    iconSize: [32, 32],
    iconAnchor: [16, 32]
});
const EventPage = () => {
	const [isLoading, setIsLoading] = useState(true);
    const { id_user } = useParams(); // Extraire id_user depuis l'URL
    const [eventData, setEventData] = useState(null);
    const [participants, setParticipants] = useState([]);
	const [newParticipant, setNewParticipant] = useState(null);
	const navigate = useNavigate();
    const [form, setForm] = useState({
        fullName: "",
        email: "",
        address: "",
        latitude: null,
        longitude: null,
		date: "",
        time: "",
        start_date: "",
        end_date: "",
        comment: "",
		fumeur: "",
        chauffeur: "",
        places: 1
    });
    const [suggestions, setSuggestions] = useState([]);
	const [emailError, setEmailError] = useState(""); // État pour les erreurs d'email
    const [shouldRefreshMap, setShouldRefreshMap] = useState(false); // État pour contrôler le rafraîchissement de la carte
    const mapRef = useRef(null); // Référence pour la carte
	const [showBanner, setShowBanner] = useState(false);
	const [errorMessage, setErrorMessage] = useState(""); // État pour les erreurs
	const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
	const [selectedParticipant, setSelectedParticipant] = useState(null);
	const [showContactModal, setShowContactModal] = useState(false);
	const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;


    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
	const apiLogoUrl = process.env.REACT_APP_LOGO_BASE_URL;
	
	const handleContactClick = (participant) => {
	  setSelectedParticipant(participant);
	  setShowContactModal(true);
	};


    // Charger les données de l'événement et des participants
    useEffect(() => {
        const fetchEventData = async () => {
            setIsLoading(true); // Commencez le chargement
            try {
                const response = await fetch(`${apiBaseUrl}/api/events/user/${id_user}`);
                if (!response.ok) {
                    throw new Error("Erreur lors du chargement des données de l'événement");
                }
                const data = await response.json();
                setEventData(data); // Utilisez directement data
                setParticipants(data.participants || []); // Gérez les participants séparément
            } catch (error) {
                console.error("Erreur lors du chargement des données :", error);
            } finally {
                setIsLoading(false); // Fin du chargement
            }
        };
        fetchEventData();
    }, [id_user, apiBaseUrl]);
	
	useEffect(() => {
		window.handleContact = (email, name, id) => {
			const participant = { email, name, id };
			setSelectedParticipant(participant);
			setShowContactModal(true);
		};

		const userToken = localStorage.getItem("authToken");
		setIsUserLoggedIn(!!userToken);

		if (userToken) {
			fetch(`${apiBaseUrl}/auth/users/me`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then(response => response.json())
			.then(data => {
				setForm(prev => ({
					...prev,
					fullName: data.full_name || prev.fullName,
					email: data.email || prev.email,
					address: data.address || prev.address,
					latitude: data.latitude ?? prev.latitude,
					longitude: data.longitude ?? prev.longitude,
					fumeur: data.fumeur || prev.fumeur,
					chauffeur: data.chauffeur || prev.chauffeur,
					comment: data.comment || prev.comment,
					places: data.places ?? prev.places
				}));
			})
			.catch(error => console.error("Erreur lors de la récupération de l'utilisateur :", error));
		}
	}, []);

    // Gestion de la saisie d'adresse avec autocomplétion
    const handleAddressChange = async (e) => {
        const value = e.target.value;
        setForm((prev) => ({ ...prev, address: value }));
        if (value.length < 3) return;
    
        try {
            const service = new window.google.maps.places.AutocompleteService();
            service.getPlacePredictions(
                { input: value, types: ["geocode"] },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                        setSuggestions(predictions.map((p) => ({ description: p.description, placeId: p.place_id })));
                    } else {
                        setSuggestions([]);
                    }
                    setIsLoading(false);
                }
            );
        } catch (error) {
            console.error("Erreur lors de l'autocomplétion :", error);
        }
    };

    // Fonction pour obtenir la géolocalisation
    const getGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
   
    
                    // Utiliser le Geocoding API de Google pour récupérer l'adresse complète
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            const fullAddress = results[0].formatted_address; // Adresse complète
    
                            // Mettre à jour l'état eventData avec les nouvelles coordonnées et l'adresse
                            setEventData((prev) => ({
                                ...prev,
                                latitude: lat,
                                longitude: lng,
                                location: fullAddress, // Mettre à jour le champ "location" avec l'adresse complète
                            }));
							// Mettre à jour l'état Form avec les nouvelles coordonnées et l'adresse
							setForm((prev) => ({
                                ...prev,
                                address: fullAddress,
                                latitude: lat,
                                longitude: lng,
                            }));
                        } else {
                            console.error("Erreur lors de la récupération de l'adresse :", status);
                            alert("Impossible de récupérer l'adresse détaillée.");
                        }
                    });
                },
                (error) => {
                    alert("Impossible d’obtenir la géolocalisation.");
                    console.error("Erreur de géolocalisation :", error.message);
                }
            );
        } else {
            alert("La géolocalisation n’est pas supportée par votre navigateur.");
        }
    };

    // Fonction de validation d'email
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setForm((prev) => ({ ...prev, email: value })); // Mettez à jour l'état form
    
        if (!validateEmail(value)) {
            setEmailError("Veuillez entrer une adresse email valide.");
        } else {
            setEmailError("");
        }
    };
	
    // Gérer la sélection d'une suggestion
    const handleSuggestionSelect = async (suggestion) => {
        setForm((prev) => ({ ...prev, address: suggestion.description }));
        setSuggestions([]);
        const geocoder = new window.google.maps.Geocoder();
        try {
            const result = await geocoder.geocode({ placeId: suggestion.placeId });
            if (result.results[0]) {
                const location = result.results[0].geometry.location;
                setForm((prev) => ({
                    ...prev,
                    latitude: location.lat(),
                    longitude: location.lng(),
                }));
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des coordonnées :", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!window.grecaptcha) {
            alert("reCAPTCHA n'est pas encore prêt. Veuillez réessayer.");
            return;
        }
    
        // Génération du token reCAPTCHA
        const token = await grecaptcha.execute(recaptchaSiteKey, { action: "submit" });
    
        // Préparation des données avec les bons noms de champs
        const participantData = {
            name: form.fullName, // Changement de fullName à name
            email: form.email,
            address: form.address,
            latitude: form.latitude,
            longitude: form.longitude,
            event_id: eventData.id, // Utilisation de event_id attendu par le backend
			comment: form.comment,
			fumeur: form.fumeur,
			chauffeur: form.chauffeur,
			places: form.places,
            token, // Ajout du token reCAPTCHA
        };
    
		
        // Validation locale des données avant soumission
        if (!participantData.name || !participantData.email || !participantData.address) {
            alert("Tous les champs obligatoires doivent être remplis.");
            return;
        }
    
        if (!validateEmail(participantData.email)) {
            alert("Veuillez entrer une adresse email valide.");
            return;
        }
    
        try {
            // Requête POST vers l'API
            const response = await fetch(`${apiBaseUrl}/api/participants`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(participantData),
            });
    
            // Vérification de la réponse du backend
            if (!response.ok) {
                const errorDetails = await response.json();
    
                if (errorDetails.detail && errorDetails.detail.includes("duplicate key value violates unique constraint")) {
                    // Erreur de duplication d'email pour cet événement
                    setErrorMessage("❌ Vous avez déjà une demande de covoiturage pour cet évènement.");
                    setTimeout(() => setErrorMessage(""), 3000); // Supprimer après 3 secondes
                } else {
                    throw new Error("Erreur lors de l'enregistrement du participant");
                }
                return;
            }
    
            // Lecture de la réponse
			const newParticipant = await response.json();
			setParticipants((prev) => [...prev, newParticipant]);
			setShouldRefreshMap(true);

			// Vérifier si l'utilisateur est connecté
			const isConnected = !!localStorage.getItem("authToken");

			if (isConnected) {
				// Redirection vers le dashboard avec sélection de l'événement
				navigate(`/dashboard?event_id=${eventData.id}`);
			} else {
				// Sinon, afficher la bannière comme actuellement
				setForm({
					fullName: "",
					email: "",
					address: "",
					latitude: null,
					longitude: null,
					comment: "",
					fumeur: "",
					chauffeur: "",
					places: 1
				});

				setShowBanner(true);
				setTimeout(() => {
					setShowBanner(false);
				}, 3000);
			}

    
            // Mise à jour de l'état des participants
            setParticipants((prev) => [...prev, newParticipant]);

            // Déclencher le rafraîchissement de la carte
            setShouldRefreshMap(true);
    
            // Réinitialisation du formulaire
            setForm({ fullName: "", email: "", address: "", latitude: null, longitude: null, comment: "" });
			
			setShowBanner(true);
            setTimeout(() => {
                setShowBanner(false);
            }, 3000);
    
        } catch (error) {
            console.error("Erreur lors de l'enregistrement :", error);
            setErrorMessage("Vous avez déjà une demande de covoiturage pour cet évènement.");
            setTimeout(() => setErrorMessage(""), 3000); // Masquer après 3 secondes
        }
    };

    if (isLoading) {
        return <p>Chargement des données de l'événement...</p>;
    }
    if (!eventData) {
        return <p>Chargement des données de l'événement...</p>;
    }
    if (!eventData.latitude || !eventData.longitude) {
        return <p>Les coordonnées de l'événement sont manquantes.</p>;
    }
	

	const userParticipant = participants.find(p => p.email === form.email);
	const markers = participants.map(p => ({
	  position: [p.latitude, p.longitude],
	  name: p.name,
	  email: p.email,
	  id: p.id,
	  address: p.address,
	  fumeur: p.fumeur,
	  chauffeur: p.chauffeur,
	  places: p.places,
	  comment: p.comment
	}));
    return (
	    <>
            {showBanner && (
                <div className="fixed top-0 left-0 w-full bg-green-500 text-white text-center p-4 font-bold shadow-md z-50">
                    ✅ Votre demande de covoiturage a été saisie avec succès !
                </div>
            )}
            
            {errorMessage && (
                <div className="fixed top-0 left-0 w-full bg-red-500 text-white text-center p-4 font-bold shadow-md">
                    ❌ {errorMessage}
                </div>
            )}
			
			
            <div className="flex h-screen">
                {/* Section gauche : Carte Google */}
                <div className="flex-1 relative">
                    <div id="map" className="absolute inset-0">
                        {/* Charger Google Maps avec l'événement et les participants */}
                        <GoogleMap
                            latitude={eventData.latitude}
                            longitude={eventData.longitude}
                            markers={markers}
							latitudeHome={form.latitude}
							longitudeHome={form.longitude}
							isUserLoggedIn={isUserLoggedIn}
							userParticipant={userParticipant}
							eventData={eventData}
							onContactClick={handleContactClick}
                        />
                    </div>
                </div>
    
                {/* Section droite : Informations sur l'événement */}
                <div className="w-1/3 bg-white p-6 overflow-y-auto">
                    <div className="mb-4">
						{/* Icône cadenas */}
						<div className="flex items-center">
							<img
								src={eventData.is_public ? "/icons/open-lock.png" : "/icons/closed-lock.png"}
								alt={eventData.is_public ? "Événement public" : "Événement privé"}
								className="w-5 h-5 mr-2"
								title={eventData.is_public ? "Événement public" : "Événement privé"}
							/>
							<h1 className="text-2xl font-bold text-gray-800 mb-2">{eventData.title}</h1>
						</div>
                        <p className="text-gray-600 mb-2">
                            <span className="font-medium">Adresse :</span> {eventData.location}
                        </p>
                    </div>
				    <div className="flex items-start space-x-4">
                        {/* Logo de l'événement */}
                        <div className="flex-shrink-0">
                            {eventData.logo ? (
                                <img
                                    src={`${apiLogoUrl}/${eventData.logo}`}
                                    alt="Logo de l'événement"
                                    className="w-full max-h-44 object-contain"
                                />
                            ) : (
                                <div className="w-24 h-24 bg-gray-100 flex items-center justify-center text-gray-500">
                                    Aucun logo
                                </div>
                            )}
                        </div>    
                        {/* Détails de l'événement */}
                        <div>
                            <p className="text-gray-600">
                                <span className="font-medium">
                                {eventData.event_type === "saisonnier" ? (
                                    <>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Date de début :</span> {" "}
										    {eventData.start_date ? (
                                                new Intl.DateTimeFormat(navigator.language, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                }).format(new Date(eventData.start_date))
                                            ) : (
                                                "Non spécifiée"
                                            )}
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Date de fin :</span> {" "}
							                {eventData.end_date ? (
                                                new Intl.DateTimeFormat(navigator.language, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                }).format(new Date(eventData.end_date))
                                            ) : (
                                                "Non spécifiée"
                                            )}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Date :</span>{" "}
                                            {eventData.date ? (
                                                new Intl.DateTimeFormat(navigator.language, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                }).format(new Date(eventData.date))
                                            ) : (
                                                "Non spécifiée"
                                            )}
                                        </p>
                                        <p className="text-gray-600">
                                            <span className="font-medium">Heure :</span> {" "}
										    {eventData.time ? (
                                                new Intl.DateTimeFormat(navigator.language, {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false // Définit un format 24h (false) ou 12h (true)
                                            }).format(new Date(`${eventData.date}T${eventData.time}`))
                                            ) : (
                                                "Non spécifiée"
                                            )}
                                        </p>
                                    </>
                                )}
							    </span>
								
								
								{/* Affichage conditionnel de l'email */}
								{eventData.is_email_visible && eventData.contact && (
									<p className="font-medium">Contact: {eventData.contact}</p>
								)}

								{/* Affichage conditionnel du téléphone */}
								{eventData.is_telephone_visible && eventData.telephone && (
									<p className="font-medium">📞 {eventData.telephone}</p>
								)}
								
								
						    </p>
                        </div>
                    </div>
    
                    {/* Formulaire pour ajouter un participant */}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Nom ou alias</label>
                            <input
                                type="text"
                                value={form.fullName}
                                onChange={(e) => setForm((prev) => ({ ...prev, fullName: e.target.value }))}
                                required
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                value={form.email}
                                onChange={handleEmailChange} // Utilisez la fonction de validation
                                required
								disabled={isUserLoggedIn}
                                className={`w-full p-2 border rounded-md ${
                                emailError ? "border-red-500" : "border-gray-300"
                                } ${isUserLoggedIn ? "bg-gray-200 cursor-not-allowed" : ""}`}
                            />
                            {emailError && (
                                <p className="text-red-500 text-sm mt-1">{emailError}</p> // Affichez l'erreur
                            )}
                        </div>
                        {/* Adresse avec autocomplétion */}
                        <div>
                            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                Mon adresse de départ
                            </label>
					        <div className="flex items-center space-x-2 mt-1">
        					    <button
                                    type="button"
                                    onClick={getGeolocation}
                                    className="mt-2 p-2 bg-blue-500 text-white rounded-full shadow hover:bg-blue-600 flex items-center justify-center"
                                    title="Utiliser ma position actuelle"
                                >
                                    <i className="fas fa-map-marker"></i>
                                </button>
                                <input
                                    type="text"
                                    id="location"
                                    name="location"
							        required
                                    value={form.address}
                                    onChange={handleAddressChange} // Gestion des saisies avec débouncing
                                    placeholder="Tapez une adresse ou géolocalisez-vous..."
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                                />
					        </div>
                            {/* Affichage des suggestions */}
                            {isLoading ? (
                                <p className="text-sm text-gray-500 mt-2">Chargement...</p>
                            ) : (
                                suggestions.length > 0 && (
                                    <ul className="border border-gray-300 bg-white mt-2 max-h-40 overflow-y-auto rounded-md shadow-lg">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleSuggestionSelect(suggestion)}
                                                className="p-2 cursor-pointer hover:bg-gray-100"
                                            >
                                                {suggestion.description}
                                            </li>
                                        ))}
                                    </ul>
                                )
                            )}
                        </div>
        
                        {/* Coordonnées Géographiques */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <input
                                    type="hidden"
                                    id="latitude"
                                    name="latitude"
                                    value={form.latitude}
                                    readOnly
                                />
                            </div>
                            <div>
                                <input
                                    type="hidden"
                                    id="longitude"
                                    name="longitude"
                                    value={form.longitude}
                                    readOnly
                                />
                            </div>
                        </div>
						{/* Êtes-vous fumeur ? */}
						<div>
							<label className="block text-sm font-medium text-gray-700">Êtes-vous fumeur ?</label>
							<select
								value={form.fumeur}
								onChange={(e) => setForm(prev => ({ ...prev, fumeur: e.target.value }))}
								className="w-full p-2 border rounded-md"
							>
								<option value="">-- Choisir --</option>
								<option value="Oui">Oui</option>
								<option value="Non">Non</option>
								<option value="Pas en voiture">Pas en voiture</option>
							</select>
						</div>

						{/* Préférences de trajet */}
						<div>
							<label className="block text-sm font-medium text-gray-700">Préférences de trajet</label>
							<select
								value={form.chauffeur}
								onChange={(e) => setForm(prev => ({ ...prev, chauffeur: e.target.value }))}
								className="w-full p-2 border rounded-md"
							>
								<option value="">-- Choisir --</option>
								<option value="Je préfère prendre ma voiture">Je préfère prendre ma voiture</option>
								<option value="Je préfère me faire conduire">Je préfère me faire conduire</option>
								<option value="Je n'ai pas de préférence">Je n'ai pas de préférence</option>
							</select>
						</div>

						{/* Nombre de places disponibles */}
						<div>
							<label className="block text-sm font-medium text-gray-700">Nombre de places disponibles</label>
							<input
								type="number"
								min="1"
								max="9"
								value={form.places}
								onChange={(e) => setForm(prev => ({ ...prev, places: parseInt(e.target.value) }))}
								className="w-full p-2 border rounded-md"
							/>
						</div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Commentaire</label>
                            <textarea
                                value={form.comment}
                                onChange={(e) => setForm((prev) => ({ ...prev, comment: e.target.value }))}
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        <button type="submit" className="w-full bg-green-500 text-white p-2 rounded-md">
                            Je veux covoiturer!
                        </button>
                    </form>
                </div>
            </div>
		</>
    );
};

export default EventPage;

// GoogleMap composant pour charger la carte
const GoogleMap = ({ latitude, longitude, latitudeHome, longitudeHome, markers, isUserLoggedIn, userParticipant, eventData }) => {
    const mapRef = useRef(null);
    const markersRef = useRef(new Map());
    const infoWindowRef = useRef(null);
    const closeTimeoutRef = useRef(null);
    const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]);

    useEffect(() => {
		
		if (!window.google || !window.google.maps) {
			console.warn("Google Maps API non encore chargée");
			return;
		}
	
        if (!mapRef.current) {
            mapRef.current = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: latitude, lng: longitude },
                zoom: 10,
            });
        }

        setMapCenter([latitudeHome, longitudeHome]);

        // Marqueur de l'utilisateur
        if (userParticipant) {
            new window.google.maps.Marker({
                position: { lat: userParticipant.latitude, lng: userParticipant.longitude },
                map: mapRef.current,
                title: "Mon domicile",
                icon: {
                    url: "/icons/maison-48.png",
                    scaledSize: new window.google.maps.Size(32, 32),
                    anchor: new window.google.maps.Point(16, 32)
                }
            });
        }

        // Marqueur de l'événement
        if (eventData?.latitude && eventData?.longitude) {
            const dateInfo = eventData.date ? new Date(eventData.date).toLocaleDateString() : '';
            const timeInfo = eventData.time ? new Date('1970-01-01T' + eventData.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';
            const label = `\${eventData.title}<br/>\${dateInfo} \${timeInfo}<br/>\${eventData.location}`;

            new window.google.maps.Marker({
                position: { lat: eventData.latitude, lng: eventData.longitude },
                map: mapRef.current,
                title: "Événement",
                icon: {
                    url: "/icons/drapeau-48.png",
                    scaledSize: new window.google.maps.Size(32, 32),
                    anchor: new window.google.maps.Point(16, 32)
                }
            }).addListener("click", () => {
                const infoWindow = new window.google.maps.InfoWindow({ content: `<strong>🚩 \${label}</strong>` });
                infoWindow.open(mapRef.current);
            });
        }

        // Participants
        markers.forEach((p) => {
            if (!userParticipant || p.email !== userParticipant.email) {
                const marker = new window.google.maps.Marker({
                    position: { lat: p.position[0], lng: p.position[1] },
                    map: mapRef.current,
                    icon: {
                        url: "/icons/autre-48.png",
                        scaledSize: new window.google.maps.Size(32, 32),
                        anchor: new window.google.maps.Point(16, 32)
                    }
                });
                marker.meta = p;

				const infoHtml = `
				  <div style='font-size:14px; max-width:200px'>
					<p><strong>Nom :</strong> ${p.name}</p>
					<p><strong>Fumeur ?</strong> ${p.fumeur || "Non précisé"}</p>
					<p><strong>Préférences :</strong> ${p.chauffeur || "Non précisé"}</p>
					<p><strong>Places :</strong> ${p.places || "Non précisé"}</p>
				  </div>
				`;

                const infoWindow = new window.google.maps.InfoWindow({ content: infoHtml });

                marker.addListener("mouseover", () => {
                    if (infoWindowRef.current) infoWindowRef.current.close();
                    infoWindow.open(mapRef.current, marker);
                    infoWindowRef.current = infoWindow;
                });

                marker.addListener("mouseout", () => {
                    closeTimeoutRef.current = setTimeout(() => infoWindow.close(), 800);
                });
            }
        });
    }, [latitude, longitude, markers, userParticipant, eventData, isUserLoggedIn]);

    return null;
};