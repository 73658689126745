import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api/api";
import { LogOut, LogIn, UserPlus, PlusCircle } from "lucide-react"; // Icône moderne
import { LayoutDashboard, Car, KeyRound } from "lucide-react"; // Ajout de l'icône Dashboard et Voiture
import { Home, Menu, X, Heart } from "lucide-react"; 
import { HeartHandshake, HelpingHand, Mail, Users } from "lucide-react";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";



const Header = () => {
	const { t } = useTranslation();
    const [user, setUser] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
	const menuRef = useRef(null);
    let timeoutRef = useRef(null);
	
    const handleLogoClick = () => {
        navigate("/");
    };
	
    // Fonction pour récupérer l'utilisateur connecté
    const fetchUser = async () => {
        const token = localStorage.getItem("authToken");
		if (!token) {
            setUser(null);
            return;
        }
        if (token) {
            try {
                const response = await api.get("/auth/users/me");
                setUser(response.data);
            } catch {
                localStorage.removeItem("authToken");
                setUser(null);
            }
        } else {
            setUser(null);
        }
    };

    useEffect(() => {
        fetchUser(); // Appelle une première fois la fonction

        // Fonction qui sera appelée quand localStorage change
        const onStorageChange = () => {
            fetchUser();
        };

        window.addEventListener("storage", onStorageChange);

        return () => {
            window.removeEventListener("storage", onStorageChange); // Nettoyage lors du démontage
        };
    }, []);
	
	// 🔹 Fermer le menu si on clique en dehors
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen]);

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        setUser(null);
        navigate("/");
        window.dispatchEvent(new Event("storage")); // 🔹 Force les autres onglets à détecter le changement
    };




	const handleDonateClick = () => {
    // Récupération de la devise en fonction du drapeau sélectionné
		const selectedCurrency = localStorage.getItem("selectedCurrency") || "EUR"; // Exemple d'utilisation du localStorage

		navigate(`/donate?amount=20&currency=${selectedCurrency}&redirect=/&showSkip=false`);
	};
	
	
    return (
        <header className="bg-white shadow-md">
            <div className="w-full mx-auto px-4 flex justify-between items-center py-4">
                <div onClick={handleLogoClick} className="flex items-center space-x-3 cursor-pointer">	
                    <img src={`/backend/static/logos/logo-covoit.png`} alt="Logo" className="h-14" />
                    <span className="text-lg font-bold text-gray-700">{t("header.title")}</span>
                </div>

                <nav className="flex items-center space-x-6">
                    {user ? (
                        <div className="flex items-center space-x-4">
                            <span className="text-gray-700 font-medium">{t("header.welcome")} { user.full_name }</span>
							<Link
                                to="/dashboard"
                                className="flex items-center space-x-2 px-4 py-2 bg-blue-200 text-blue-800 rounded-md hover:bg-blue-300 font-medium rounded-md transition-all duration-300"
                            >
                                <Car size={20} />
                                <span>{t("header.dashboard")}</span>
                            </Link>

                        </div>
                    ) : (
                        <>
                            <Link
                                to="/login"
                                className="flex items-center space-x-2 px-4 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100 hover:text-gray-900 transition-all duration-300"
                            >
                                <LogIn size={20} />
                                <span>{t("header.login")}</span>
                            </Link>
                            <Link
                                to="/signup"
                                className="flex items-center space-x-2 px-4 py-2 bg-blue-200 text-blue-800 rounded-md hover:bg-blue-300 font-medium rounded-md transition-all duration-300"
                            >
                                <UserPlus size={20} />
                                <span>{t("header.signup")}</span>
                            </Link>
                        </>
                    )}
                    

				<Link
                    to="/event/create"
                    className="flex items-center space-x-2 px-4 py-2 bg-green-200 text-green-800 rounded-md hover:bg-green-300 font-medium rounded-md transition-all duration-300"
                >
                    <PlusCircle size={20} />
                    <span>{t("header.create_event")}</span>
                </Link>				
				<LanguageSelector />				
				<Link to="/" className="text-gray-700 hover:text-gray-900 transition">
                    <Home size={30} />
                </Link>

					
                {/* Menu burger : Seulement si utilisateur connecté */}
                {user && (
                <div className="relative">
                    <button 
                        onClick={() => setMenuOpen(!menuOpen)}
                        className="text-gray-700 focus:outline-none"
                    >
                        {menuOpen ? <X size={32} /> : <Menu size={32} />}
                    </button>

                    {menuOpen && (
					  <div
						ref={menuRef}
						className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-xl shadow-xl z-50 py-2"
					  >
						<ul className="flex flex-col">
						
						  <li>
							<Link
							  to="/profil"
							  className="flex items-center px-4 py-3 w-full text-gray-700 hover:bg-gray-100 transition-all"
							>
							  <i className="fas fa-user-circle mr-3"></i>
							  <span>{t("header.profile")}</span>
							</Link>
						  </li>
						  <li>
							<button
							  onClick={handleDonateClick}
							  className="flex items-center px-4 py-3 w-full text-gray-700 hover:bg-gray-100 transition-all"
							>
							  <HeartHandshake  size={20} />
							  <span className="ml-2">{t("header.donate")}</span>
							</button>
						  </li>

						  
						  <li>
						    <Link
							  to="/qui-sommes-nous"
					  	      className="w-full flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-green-600 transition-all duration-300"
						    >
							  <Users size={20} />
							  <span className="hidden md:inline">Qui sommes-nous ?</span>
						    </Link>
						  </li>
						  <li>
						    <Link
  							to="/contact"
							  className="w-full flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-blue-600 transition-all duration-300"
						    >
  							  <Mail size={20} />
							  <span className="hidden md:inline">{t("header.contact")}</span>
						    </Link>
						  </li>
						  <li>
							<Link
							  to="/change-password"
							  className="flex items-center px-4 py-3 w-full text-gray-700 hover:bg-gray-100 transition-all"
							>
							  <KeyRound size={22} className="mr-3" />
							  <span>{t("header.change_password")}</span>
							</Link>
						  </li>
						  <li>
							<button
							  onClick={handleLogout}
							  className="flex items-center px-4 py-3 w-full text-gray-700 hover:bg-gray-100 transition-all"
							>
							  <LogOut size={22} className="mr-3" />
							  <span>{t("header.logout")}</span>
							</button>
						  </li>
						</ul>
					  </div>
					)}
                </div>
                )}
                </nav>
            </div>
        </header>
    );
};

export default Header;