import React from "react";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";

const Cgu = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/signup");
  };

  return (
    <div className="relative p-6 max-w-4xl mx-auto bg-white shadow-md rounded-md">
      {/* Bouton de fermeture */}
      <button
        onClick={handleClose}
        className="absolute top-4 right-4 text-gray-600 hover:text-red-600"
        title="Fermer les CGU"
      >
        <X size={24} />
      </button>

      <h1 className="text-2xl font-bold mb-4">Conditions Générales d'Utilisation</h1>
      <p className="mb-4">Dernière mise à jour : 22 mars 2025</p>

      <h2 className="font-semibold mb-2">1. Acceptation des CGU</h2>
      <p className="mb-4">
        En utilisant le site EventByCar, l'utilisateur accepte sans réserve les présentes Conditions Générales d’Utilisation. Si l'utilisateur n'accepte pas ces conditions, il doit cesser d'utiliser le site immédiatement.
      </p>

      <h2 className="font-semibold mb-2">2. Nature du service</h2>
      <p className="mb-4">
        EventByCar est une plateforme de mise en relation entre particuliers pour organiser des covoiturages liés à des événements. EventByCar n'est ni un transporteur ni un intermédiaire professionnel. Le service repose uniquement sur la bonne foi des utilisateurs.
      </p>

      <h2 className="font-semibold mb-2">3. Absence de garantie</h2>
      <p className="mb-4">
        L’accès au site est fourni "tel quel" et sans garantie d’aucune sorte. L’éditeur ne garantit pas :
        <ul className="list-disc ml-6">
          <li>L’exactitude ou la véracité des informations fournies par les utilisateurs.</li>
          <li>La ponctualité, la disponibilité ou la qualité du covoiturage proposé.</li>
          <li>L'absence d’incident, de litige, d’accident, de vol, ou de comportement inapproprié entre utilisateurs.</li>
        </ul>
      </p>

      <h2 className="font-semibold mb-2">4. Limitation de responsabilité</h2>
      <p className="mb-4">
        L’utilisateur reconnaît utiliser la plateforme sous sa seule responsabilité. En aucun cas EventByCar, son éditeur ou ses représentants ne pourront être tenus responsables :
        <ul className="list-disc ml-6">
          <li>Des accidents, blessures, ou dommages corporels survenus durant un trajet.</li>
          <li>Des pertes financières ou matérielles.</li>
          <li>Des retards, absences, annulations de trajet ou mauvaises rencontres.</li>
          <li>De toute infraction au Code de la route commise par les utilisateurs.</li>
          <li>Des conséquences d’un comportement abusif, frauduleux ou illégal de la part d’un utilisateur.</li>
        </ul>
      </p>

      <h2 className="font-semibold mb-2">5. Obligations des utilisateurs</h2>
      <p className="mb-4">
        Les utilisateurs s’engagent à :
        <ul className="list-disc ml-6">
          <li>Fournir des informations exactes lors de leur inscription ou participation à un événement.</li>
          <li>Ne pas usurper l’identité d’un tiers.</li>
          <li>Respecter les autres utilisateurs, les règles de sécurité et la législation en vigueur.</li>
          <li>Conduire de manière responsable (pour les conducteurs) et respecter les règles de la route.</li>
        </ul>
      </p>

      <h2 className="font-semibold mb-2">6. Vérification et modération</h2>
      <p className="mb-4">
        EventByCar n’est pas en mesure de vérifier l’identité ou la fiabilité des utilisateurs. Aucun contrôle n’est effectué sur :
        <ul className="list-disc ml-6">
          <li>La possession ou la validité du permis de conduire.</li>
          <li>L’assurance du véhicule utilisé.</li>
          <li>Les antécédents des conducteurs ou passagers.</li>
        </ul>
      </p>

      <h2 className="font-semibold mb-2">7. Données personnelles</h2>
      <p className="mb-4">
        Les informations collectées sont utilisées uniquement pour le bon fonctionnement de la plateforme (mise en relation, notifications, emails, géolocalisation). Chaque utilisateur peut demander la suppression de ses données à tout moment, conformément au RGPD.
      </p>

      <h2 className="font-semibold mb-2">8. Sécurité et précautions</h2>
      <p className="mb-4">
        Les utilisateurs sont fortement invités à :
        <ul className="list-disc ml-6">
          <li>Effectuer un premier contact par messagerie ou email avant le trajet.</li>
          <li>Prendre connaissance des coordonnées et préférences du conducteur/passager.</li>
          <li>Avertir un proche avant un trajet.</li>
          <li>Ne jamais effectuer de paiement en espèces ou par avance via la plateforme.</li>
          <li>Se désister en cas de doute ou de sentiment d’insécurité.</li>
        </ul>
      </p>

      <h2 className="font-semibold mb-2">9. Fonctionnement communautaire</h2>
      <p className="mb-4">
        EventByCar repose sur la confiance et le respect mutuel. Les utilisateurs doivent signaler tout abus ou comportement dangereux via le formulaire de contact prévu à cet effet.
      </p>

      <h2 className="font-semibold mb-2">10. Propriété intellectuelle</h2>
      <p className="mb-4">
        Tous les éléments du site (textes, images, logos, code, design, etc.) sont protégés par le droit de la propriété intellectuelle. Toute reproduction ou utilisation sans autorisation est interdite.
      </p>

      <h2 className="font-semibold mb-2">11. Suspension / suppression du compte</h2>
      <p className="mb-4">
        EventByCar se réserve le droit de suspendre ou supprimer un compte utilisateur, à tout moment, sans préavis, en cas de non-respect des présentes CGU, de comportement abusif, ou à la demande d’une autorité judiciaire.
      </p>

      <h2 className="font-semibold mb-2">12. Modifications des CGU</h2>
      <p className="mb-4">
        Les présentes CGU peuvent être modifiées à tout moment. Les utilisateurs seront informés via une publication sur le site. L’usage continu du site après modification vaut acceptation.
      </p>

      <h2 className="font-semibold mb-2">13. Droit applicable et juridiction compétente</h2>
      <p className="mb-4">
        Les présentes CGU sont soumises au droit français. Tout litige sera soumis aux juridictions compétentes du ressort du siège de l’éditeur du site.
      </p>

      <h2 className="font-semibold mb-2">14. Contact</h2>
      <p className="mb-4">
        Pour toute question ou signalement : <br/>
        <a href="mailto:chatraz@gmail.com" className="text-blue-600">chatraz@gmail.com</a><br/>
      </p>
    </div>
  );
};

export default Cgu;
