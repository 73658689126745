import React, { useState, useEffect, useRef } from "react";
import {
  Menu,
  X,
  LogOut,
  LogIn,
  UserPlus,
  PlusCircle,
  Car,
  KeyRound,
  HeartHandshake,
  Home,
  User,
  Mail,
  Users
} from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import api from "../api/api";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

const HeaderMobile = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef();

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setUser(null);
        return;
      }
      try {
        const res = await api.get("/auth/users/me", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(res.data);
      } catch {
        localStorage.removeItem("authToken");
        setUser(null);
      }
    };

    fetchUser();
    window.addEventListener("storage", fetchUser);
    return () => window.removeEventListener("storage", fetchUser);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    if (menuOpen) document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuOpen]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setUser(null);
    navigate("/");
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <header className="bg-white shadow-md px-4 py-3 flex justify-between items-center">
      <div onClick={() => navigate("/")} className="flex items-center space-x-2 cursor-pointer">
        <img src="/backend/static/logos/logo-covoit.png" alt="Logo" className="h-10" />
        <span className="text-lg font-bold text-gray-700">EventByCar</span>
      </div>

      <div className="flex items-center space-x-3">
        <LanguageSelector />
        <button onClick={() => navigate("/event/create")} title={t("header.create_event")} className="text-green-600">
          <PlusCircle size={26} />
        </button>
        {user && (
          <button onClick={() => navigate("/dashboard")} title={t("header.dashboard")} className="text-blue-600">
            <Car size={26} />
          </button>
        )}
        <button onClick={() => setMenuOpen(true)} className="text-gray-700">
          <Menu size={28} />
        </button>
      </div>

      {menuOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <div
            ref={menuRef}
            className="fixed top-0 right-0 w-64 h-full bg-white shadow-lg p-4 overflow-y-auto"
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">{t("header.menu")}</h2>
              <button onClick={() => setMenuOpen(false)}>
                <X size={24} />
              </button>
            </div>
            <ul className="space-y-2">
              <li>
                <Link to="/" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                  <Home size={18} />
                  <span>{t("header.home")}</span>
                </Link>
              </li>
              <li>
                <Link to="/event/create" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                  <PlusCircle size={18} />
                  <span>{t("header.create_event")}</span>
                </Link>
              </li>
              {user && (
                <>
                  <li>
                    <Link to="/dashboard" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                      <Car size={18} />
                      <span>{t("header.dashboard")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/profil" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                      <User size={18} />
                      <span>{t("header.profile")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                      <Mail size={18} />
                      <span>{t("header.contact")}</span>
                    </Link>
                  </li>
                </>
              )}
              {!user && (
                <>
                  <li>
                    <Link to="/login" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                      <LogIn size={18} />
                      <span>{t("header.login")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/signup" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                      <UserPlus size={18} />
                      <span>{t("header.signup")}</span>
                    </Link>
                  </li>
                </>
              )}
              <li>
                <button
                  onClick={() => {
                    setMenuOpen(false);
                    navigate(`/donate?amount=20&currency=EUR&redirect=/&showSkip=false`);
                  }}
                  className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md"
                >
                  <HeartHandshake size={18} />
                  <span>{t("header.donate")}</span>
                </button>
              </li>
              {user && (
                <>
                  <li>
                    <Link to="/change-password" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                      <KeyRound size={18} />
                      <span>{t("header.change_password")}</span>
                    </Link>
                  </li>
                  <li>
                    <button onClick={() => { handleLogout(); setMenuOpen(false); }} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                      <LogOut size={18} />
                      <span>{t("header.logout")}</span>
                    </button>
                  </li>
                </>
              )}
              <li>
                <Link to="/qui-sommes-nous" onClick={() => setMenuOpen(false)} className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md">
                  <Users size={18} />
                  <span>Qui sommes-nous ?</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default HeaderMobile;
