import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { FaCloudUploadAlt, FaCheckCircle, FaTimesCircle, FaLock, FaGlobe } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";

const EventAdminMobile = () => {
  const { id_admin } = useParams();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiBaseLogoUrl = process.env.REACT_APP_LOGO_BASE_URL;

  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const res = await fetch(`${apiBaseUrl}/api/events/admin/${id_admin}`);
        const data = await res.json();
        setEventData(data);
        setEmail(data.contact || "");
        setIsEmailVisible(data.is_email_visible);
        setPhone(data.telephone || "");
        setIsPhoneVisible(data.is_telephone_visible);
        if (data.logo) {
          setLogoPreview(`${apiBaseLogoUrl}/${data.logo}`);
        }
      } catch (err) {
        console.error("Erreur :", err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEvent();
  }, [id_admin]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (logoPreview) URL.revokeObjectURL(logoPreview);
      setLogoFile(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("title", eventData.title);
    formData.append("contact", email);
    if (logoFile) formData.append("logo", logoFile);
    formData.append("telephone", phone.trim() === "" ? null : phone);
    formData.append("is_email_visible", isEmailVisible);
    formData.append("is_telephone_visible", isPhoneVisible);

    if (eventData.event_type === "saisonnier") {
      formData.append("start_date", eventData.start_date);
      formData.append("end_date", eventData.end_date);
    } else {
      formData.append("date", eventData.date);
      formData.append("time", eventData.time);
    }

    try {
      const res = await fetch(`${apiBaseUrl}/api/events/admin/${id_admin}`, {
        method: "PUT",
        body: formData
      });
      if (!res.ok) throw new Error("Échec mise à jour");
      setShowBanner(true);
      setTimeout(() => setShowBanner(false), 3000);
    } catch (err) {
      console.error("Erreur lors de l'enregistrement :", err);
      alert("Erreur enregistrement");
    }
  };

  if (isLoading || !eventData) return <p className="p-4 text-center">Chargement...</p>;

  return (
    <div className="p-4 space-y-6">
      {showBanner && (
        <div className="bg-green-100 text-green-800 text-center py-2 rounded-md shadow transition-opacity duration-500 ease-in-out opacity-100 animate-fade-in-out">
          ✅ Enregistrement effectué avec succès !
        </div>
      )}
	<div className="bg-white rounded-xl shadow p-4">
      <h2 className="text-xl font-bold">
	    {eventData.title} <span className="text-sm text-gray-500">({eventData.is_public ? 'Public' : 'Privé'})</span>
	  </h2>
	  <p className="text-sm text-gray-500 leading-tight">{eventData.location}</p>
	</div>

      <div>
        <label className="block text-sm mb-1">Logo</label>
        <div className="border p-4 rounded-md text-center bg-gray-50">
          <label htmlFor="logo" className="cursor-pointer flex flex-col items-center">
            {logoPreview ? (
              <>
                <img src={logoPreview} alt="Logo preview" className="h-24 object-contain mb-2" />
                <span className="text-sm text-gray-600">Changer le logo</span>
              </>
            ) : (
              <div className="flex items-center gap-2">
                <FaCloudUploadAlt className="text-blue-500 text-2xl" />
                <span className="text-sm">Choisir un logo</span>
              </div>
            )}
            <input
              type="file"
              id="logo"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
          </label>
        </div>
      </div>

      <div>
        <label className="block text-sm mb-1">Email</label>
        <div className="relative">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded-md pr-12"
          />
          <button
            type="button"
            onClick={() => setIsEmailVisible(!isEmailVisible)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-xl"
          >
            {isEmailVisible ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle />}
          </button>
        </div>
        <p className="text-sm text-gray-600 mt-1">
          {isEmailVisible ? "Email visible sur votre site." : "Email non visible sur votre site."}
        </p>
      </div>

      <div>
        <label className="block text-sm mb-1">Téléphone</label>
        <div className="relative">
          <PhoneInput
            country="fr"
            value={phone}
            onChange={setPhone}
            inputProps={{ className: "w-full p-2 border rounded-md pr-12" }}
          />
          <button
            type="button"
            onClick={() => setIsPhoneVisible(!isPhoneVisible)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-xl"
          >
            {isPhoneVisible ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle />}
          </button>
        </div>
        <p className="text-sm text-gray-600 mt-1">
          {isPhoneVisible ? "Téléphone visible sur votre site." : "Téléphone non visible sur votre site."}
        </p>
      </div>

      {eventData.event_type === "saisonnier" ? (
        <>
          <div>
            <label className="block text-sm mb-1">Date de début</label>
            <input
              type="date"
              value={eventData.start_date || ""}
              onChange={(e) => setEventData({ ...eventData, start_date: e.target.value })}
              className="w-full p-2 border rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm mb-1">Date de fin</label>
            <input
              type="date"
              value={eventData.end_date || ""}
              onChange={(e) => setEventData({ ...eventData, end_date: e.target.value })}
              className="w-full p-2 border rounded-md"
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <label className="block text-sm mb-1">Date</label>
            <input
              type="date"
              value={eventData.date || ""}
              onChange={(e) => setEventData({ ...eventData, date: e.target.value })}
              className="w-full p-2 border rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm mb-1">Heure</label>
            <input
              type="time"
              value={eventData.time || ""}
              onChange={(e) => setEventData({ ...eventData, time: e.target.value })}
              className="w-full p-2 border rounded-md"
            />
          </div>
        </>
      )}

      <button
        onClick={handleSave}
        className="w-full py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
      >
        Enregistrer les modifications
      </button>
    </div>
  );
};

export default EventAdminMobile;
