import React, { useEffect, useState, useRef } from "react";
import EventMap from "../components/Map/EventMap";
import api from "../api/api";

const DashboardMobile = () => {
  const [matchRequests, setMatchRequests] = useState([]);
  const longPressTimeout = useRef(null);

  const recenterMap = (lat, lng) => {
    setMapCenter([lat, lng]);
  };

  useEffect(() => {
    const fetchMatchRequests = async () => {
      try {
        const res = await api.get("/api/match-requests");
        setMatchRequests(res.data);
      } catch (err) {
        console.error("Erreur chargement des demandes :", err);
      }
    };
    fetchMatchRequests();
  }, []);

  const getEventNotifications = (eventId) => {
    return matchRequests.filter(req => req.event_id === eventId);
  };

  const [user, setUser] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [message, setMessage] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoParticipant, setInfoParticipant] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  
  const handleContactClick = (participant) => {
    if (participant.latitude && participant.longitude) {
      recenterMap(participant.latitude, participant.longitude);
    }
  
    setSelectedParticipant(participant);
    setShowContactModal(true);
    setMessage("");
    setSentSuccess(false);
  };
	const handleContactRecenterClick = (participant) => {
	  if (participant.latitude && participant.longitude) {
		recenterMap(participant.latitude, participant.longitude);
	  }
	};

  useEffect(() => {
    const fetchUserAndEvents = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const resUser = await api.get("/auth/users/me", {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(resUser.data);

        const resEvents = await api.get(`/api/events/by-email/${encodeURIComponent(resUser.data.email)}`);
        setEvents(resEvents.data);
        if (resEvents.data.length > 0) {
          setSelectedEvent(resEvents.data[0]);
        }
      } catch (err) {
        console.error("Erreur utilisateur ou événements :", err);
      }
    };

    fetchUserAndEvents();
  }, []);

  useEffect(() => {
    if (user && selectedEvent) {
      const participant = selectedEvent.participants?.find(p => p.email === user.email);
      if (participant) {
        setMapCenter([participant.latitude, participant.longitude]);
      } else {
        setMapCenter([selectedEvent.latitude, selectedEvent.longitude]);
      }
    }
  }, [user, selectedEvent]);

  const refreshMatchRequests = async () => {
	  try {
		  const response = await api.get("/api/match-requests"); // Recharger la liste
		  setMatchRequests(response.data);
	  } catch (error) {
		  console.error("❌ Erreur lors de la mise à jour des match requests :", error);
	  }
  };
  
	const handleMarkerClick = (participant) => {
		setSelectedParticipant(participant);
		setShowSidebar(true);
	};

  return (
    <div className="flex flex-col h-screen">
      {/* Carte en haut */}
      <div className="h-1/2 w-full">
        {user && selectedEvent && (
          <EventMap 
			  position={mapCenter}
			  eventLocation={{
				latitude: selectedEvent.latitude,
				longitude: selectedEvent.longitude,
				name: selectedEvent.title
			  }}
			  userParticipant={selectedEvent.participants.find(p => p.email === user?.email)}
			  markers={[
  			  ...selectedEvent.participants.map(p => ({
				  position: [p.latitude, p.longitude],
				  name: p.name,
				  email: p.email,
				  id: p.id
			    })),
			    {
  				position: [selectedEvent.latitude, selectedEvent.longitude],
				  name: selectedEvent.title,
				  email: "event",
				  id: `event-${selectedEvent.id}`
			    }
			  ]}
			  onMarkerClick={handleMarkerClick}
			  user={user}
			  selectedEvent={selectedEvent}
			  refreshMatchRequests={refreshMatchRequests}
			/>
        )}
      </div>

      {/* Événements en bas */}
  	<div className="h-1/2 overflow-x-auto flex space-x-4 p-4 snap-x snap-mandatory bg-gray-50">
	    {events.map(event => (
  		<div
		    key={event.id}
		    className="min-w-[250px] max-w-[280px] bg-white shadow rounded-lg p-3 snap-center flex-shrink-0 border border-gray-200"
		    onClick={() => setSelectedEvent(event)}
		  >
  		  {/* Titre & cadenas */}
		    <div className="flex items-center mb-1">
  			<img
			    src={event.is_public ? "/icons/open-lock.png" : "/icons/closed-lock.png"}
			    alt="lock"
			    className="w-4 h-4 mr-2"
			  />
			  <h2 className="text-sm font-semibold text-gray-800 truncate">{event.title}</h2>
		    </div>
  
		    {/* Lieu */}
		    <p className="text-xs text-gray-600 truncate">📍 {event.location}</p>
  
		    {/* Date */}
		    <p className="text-xs text-gray-500 mt-1">
  			{event.date
			    ? new Date(event.date).toLocaleDateString()
			    : event.start_date && event.end_date
			    ? `Du ${new Date(event.start_date).toLocaleDateString()} au ${new Date(event.end_date).toLocaleDateString()}`
			    : "Date non précisée"}
		    </p>
			{getEventNotifications(event.id).length > 0 && (
			  <div className="mt-2">
				{getEventNotifications(event.id).map((req, idx) => {
					const currentParticipant = event.participants?.find(p => p.email === user.email);

					const other = currentParticipant && req.sender_id === currentParticipant.id
					  ? event.participants?.find(p => p.id === req.receiver_id)
					  : event.participants?.find(p => p.id === req.sender_id);

				  return other ? (
					<div
					  key={idx}
					  className="text-xs bg-blue-100 p-2 rounded mb-1 cursor-pointer"
					  onClick={() => handleContactRecenterClick(other)}
						onTouchStart={() => {
						  longPressTimeout.current = setTimeout(() => {
							setInfoParticipant(other);
							setShowInfoModal(true);
						  }, 600);
						}}
						onTouchEnd={() => clearTimeout(longPressTimeout.current)}
					>
					  👤 {other.name} <br />
					  <div className="flex items-center justify-between mt-1">
  					  <button
						  className="px-2 py-1 bg-blue-500 text-white text-xs rounded"
						  onClick={() => handleContactClick(other)}
					    >
  						Contacter
					    </button>
					    <span className="text-[10px] text-gray-500 ml-2">ℹ️ Infos : restez appuyé</span>
					  </div>
					</div>
				  ) : null;
				})}
			  </div>
			)}

		  </div>
	    ))}
	  </div>
	  {showContactModal && selectedParticipant && (
		  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="bg-white rounded-lg shadow-lg p-4 w-[90%] max-w-md max-h-[90vh] overflow-y-auto">
			  <h2 className="text-lg font-semibold mb-2">Contacter {selectedParticipant.name}</h2>

			  {sentSuccess ? (
				<p className="text-green-600 text-sm mb-2">✅ Message envoyé avec succès.</p>
			  ) : (
				<>
				  <textarea
				    autoFocus
					className="w-full border rounded p-2 h-32 text-sm"
					placeholder="Votre message..."
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				  />
				  <div className="flex justify-end space-x-2 mt-3">
					<button
					  onClick={() => {
						setShowContactModal(false);
						setSentSuccess(false);
						setLoadingEmail(false);
					  }}
					  className="px-3 py-1 border text-gray-600 rounded"
					>
					  Annuler
					</button>
					<button
  					onClick={async () => {
					    setLoadingEmail(true);
					    try {
							
						const currentParticipant = selectedEvent.participants?.find(p => p.email === user.email);
						if (!currentParticipant) {
						  alert("Impossible de trouver votre identifiant participant.");
					  	  return;
						}
						  
  						await api.post("/api/participants/send_email_contact", {
						    to_email: selectedParticipant.email,
						    sender_name: currentParticipant.name,
						    event_title: selectedEvent.title,
						    message
						  });
  
						  // ✅ Match request : on la crée ici une seule fois (la 1ère fois)
						  try {
  						  await api.post("/api/match-requests/create", {
							  sender_id: currentParticipant?.id,
							  receiver_id: selectedParticipant.id,
							  event_id: selectedEvent.id,
							  status: "pending",
							  message
						    });
						  } catch (err) {
  						  if (err.response?.status === 400) {
							  console.log("📦 Match request déjà existante, c’est ok.");
						    } else {
  							throw err;
						    }
						  }
  
						  setSentSuccess(true);
						  setMessage("");
  
						  // ✅ Fermeture après succès, même si match request existe déjà
						  setTimeout(() => {
  						  setShowContactModal(false);
						    setSentSuccess(false);
						  }, 1500);
					    } catch (err) {
  						alert("Erreur lors de l'envoi");
						  console.error(err);
					    } finally {
  						setLoadingEmail(false);
					    }
					  }}
					  disabled={loadingEmail || sentSuccess}
					  className={`px-3 py-1 rounded text-white ${
						loadingEmail ? "bg-gray-400" :
						sentSuccess ? "bg-green-500" :
						"bg-blue-500 hover:bg-blue-600"
					  }`}
					>
					  {loadingEmail ? "Envoi..." : sentSuccess ? "Envoyé" : "Envoyer"}
					</button>
				  </div>
				</>
			  )}
			</div>
		  </div>
		)}
		
		{showInfoModal && infoParticipant && (
		  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="bg-white rounded-lg shadow-lg p-4 w-[90%] max-w-sm">
			  <h2 className="text-lg font-bold mb-2">{infoParticipant.name}</h2>
			  <p><strong>Places dispo :</strong> {infoParticipant.places || "Non précisé"}</p>
			  <p><strong>Fumeur ?</strong> {infoParticipant.fumeur || "Non précisé"}</p>
			  <p><strong>Préférence :</strong> {infoParticipant.chauffeur || "Non précisé"}</p>
			  <p><strong>Commentaire :</strong> {infoParticipant.comment || "Aucun"}</p>
			  <button
				onClick={() => setShowInfoModal(false)}
				className="mt-4 px-3 py-1 bg-blue-500 text-white rounded"
			  >
				Fermer
			  </button>
			</div>
		  </div>
		)}
    </div>
	
  );
};

export default DashboardMobile;