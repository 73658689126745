import React, { useEffect, useState } from "react";
import EventPage from "./EventPage";
import EventPageMobile from "./EventPageMobile";

const EventPageRedirect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  if (isMobile === null) return null; // Affiche rien le temps de savoir

  return isMobile ? <EventPageMobile /> : <EventPage />;
};

export default EventPageRedirect;
