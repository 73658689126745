import React from "react";
import ReactDOM from "react-dom/client"; // Nouvelle m�thode
import App from "./App";
import "./styles/tailwind.css"; // Assurez-vous que TailwindCSS est correctement import�
import "./i18n"; 

const root = ReactDOM.createRoot(document.getElementById("root")); // Utilisation de createRoot
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
