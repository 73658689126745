import React, { useEffect, useState } from "react";
import CreateEvent from "./CreateEvent";
import CreateEventMobile from "./CreateEventMobile";

const CreateEventRedirect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const check = () => setIsMobile(window.innerWidth < 768);
    check();
    window.addEventListener("resize", check);
    return () => window.removeEventListener("resize", check);
  }, []);

  if (isMobile === null) return null;

  return isMobile ? <CreateEventMobile /> : <CreateEvent />;
};

export default CreateEventRedirect;
