import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaGoogle, FaFacebook } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignupMobile = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [acceptCgu, setAcceptCgu] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showCguError, setShowCguError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOAuthSignup = (provider) => {
    window.location.href = `${apiBaseUrl}/auth/${provider}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (!acceptCgu) {
      setShowCguError(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(t("signup.invalid_email"));
      return;
    }

    if (password.length < 8 || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
      setError(t("signup.password_requirement"));
      return;
    }

    if (fullName.trim() === "") {
      setError(t("signup.full_name_required"));
      return;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password, full_name: fullName })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.detail || "Erreur lors de l'inscription.");
      setSuccess(t("signup.email_verification"));
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="px-4 pt-8 pb-16 text-left">
      <h1 className="text-2xl font-bold text-center mb-6">{t("signup.title")}</h1>
      {error && <p className="text-red-500 text-sm text-center mb-2">{error}</p>}
      {success && <p className="text-green-500 text-sm text-center mb-2">{success}</p>}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">{t("signup.email")}</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded-md"
            required
          />
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">{t("signup.password")}</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border rounded-md pr-10"
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">{t("signup.full_name")}</label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="w-full p-2 border rounded-md"
            required
          />
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="acceptCgu"
            checked={acceptCgu}
            onChange={(e) => {
              setAcceptCgu(e.target.checked);
              setShowCguError(false);
            }}
            className="h-4 w-4"
            required
          />
          <label htmlFor="acceptCgu" className="text-sm">
            J’accepte les <a href="/cgu" target="_blank" className="text-blue-600 underline">CGU</a>
          </label>
        </div>
        {showCguError && <p className="text-red-500 text-sm">Vous devez accepter les CGU.</p>}

        <button
          type="submit"
          disabled={!acceptCgu}
          className={`w-full py-2 text-white rounded-md ${
            acceptCgu ? "bg-green-500 hover:bg-green-600" : "bg-gray-400 cursor-not-allowed"
          }`}
        >
          {t("signup.signup_button")}
        </button>
      </form>

      <div className="text-center my-4 text-gray-500">ou</div>

      <div className="space-y-3">
        <button
          onClick={() => handleOAuthSignup("google")}
          className="w-full flex items-center justify-center px-4 py-2 border rounded-md hover:bg-green-100"
        >
          <FaGoogle className="mr-2 text-red-500" /> {t("signup.oauth_google")}
        </button>
        <button
          onClick={() => handleOAuthSignup("facebook")}
          className="w-full flex items-center justify-center px-4 py-2 border rounded-md hover:bg-green-100"
        >
          <FaFacebook className="mr-2 text-blue-600" /> {t("signup.oauth_facebook")}
        </button>
      </div>
    </div>
  );
};

export default SignupMobile;