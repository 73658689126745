import React, { useEffect, useState } from "react";
import { FaGoogle, FaFacebook, FaMicrosoft, FaApple } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);

		const token = urlParams.get("token");

		if (token) {
			localStorage.setItem("authToken", token);
			window.dispatchEvent(new Event("storage"));

			const isNew = urlParams.get("new_user") === "true";
			const destination = isNew ? "/profil" : "/dashboard";

			navigate(destination, { replace: true });
		}
			}, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await api.post("/auth/login", { email, password });
            if (response.data && response.data.access_token) {
                localStorage.setItem("authToken", response.data.access_token);
                window.dispatchEvent(new Event("storage"));
                navigate("/dashboard", { replace: true });
            }
        } catch (error) {
            console.error("❌ Erreur de connexion :", error.response?.data?.detail || error.message);
            setError(error.response?.data?.detail || "Email ou mot de passe incorrect.");
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = "https://eventbycar.com/backend/auth/google";
    };
	const handleFacebookLogin = () => {
		window.location.href = "https://eventbycar.com/backend/auth/facebook";
	};

    return (
        <div className="container mx-auto mt-10 text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Connexion</h1>

            {error && <p className="text-red-500">{error}</p>}

            <form onSubmit={handleSubmit} className="space-y-4 flex flex-col items-center">
                <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700 text-left">Email</label>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm" 
                        required 
                    />
                </div>
				<div className="w-1/2">
				  <label className="block text-sm font-medium text-gray-700 text-left">Mot de passe</label>
				  <div className="relative">
					<input 
					  type={showPassword ? "text" : "password"}
					  value={password} 
					  onChange={(e) => setPassword(e.target.value)} 
					  className="mt-1 block w-full p-2 border rounded-md shadow-sm pr-10" 
					  required 
					/>
					<span
					  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
					  onClick={() => setShowPassword(!showPassword)}
					>
					  {showPassword ? <FaEyeSlash /> : <FaEye />}
					</span>
				  </div>
				</div>
                <button 
                    type="submit" 
                    className="w-1/2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                >
                    Se connecter
                </button>
				<div className="mt-4">
					<a href="/forgot-password" className="text-blue-600 hover:underline">
						Mot de passe oublié ?
					</a>
				</div>
				<br/>
				<div className="my-2 text-center">ou</div>
				<br/>
				<div className="w-1/2 mt-4 flex flex-col space-y-3">
					<button 
						onClick={handleGoogleLogin}
						className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md bg-transparent hover:bg-green-100 transition-colors">
						<FaGoogle className="text-red-500 mr-2" /> {t("login.oauth_google")}
					</button>
					<button 
						onClick={handleFacebookLogin}
						className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md bg-transparent hover:bg-green-100 transition-colors">
						<FaFacebook className="text-blue-600 mr-2" /> {t("login.oauth_facebook")}
					</button>
				</div>


            </form>
        </div>
    );
};

export default LoginPage;
