// HeaderRedirect.js
import React, { useEffect, useState } from "react";
import Header from "./Header"; // Desktop
import HeaderMobile from "./HeaderMobile"; // Mobile

const HeaderRedirect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  if (isMobile === null) return null; // ou spinner

  return isMobile ? <HeaderMobile /> : <Header />;
};

export default HeaderRedirect;
