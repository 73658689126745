import React, { useEffect, useState, useCallback } from "react";
import api from "../api/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Profil = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fumeur, setFumeur] = useState("");
  const [chauffeur, setChauffeur] = useState("");
  const [comment, setComment] = useState("");
  const [fullName, setFullName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await api.get("/auth/users/me");
        setUser(res.data);
		setFullName(res.data.full_name || "");
        setAddress(res.data.address || "");
        setLatitude(res.data.latitude || null);
        setLongitude(res.data.longitude || null);
        setFumeur(res.data.fumeur || "");
        setChauffeur(res.data.chauffeur || "");
        setComment(res.data.comment || "");
      } catch (err) {
        console.error("Erreur lors du chargement du profil:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  const fetchSuggestions = useCallback(async (value) => {
    if (value.length < 3) return;
    setIsLoading(true);
    try {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        { input: value, types: ["geocode"], componentRestrictions: { country: "fr" } },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            setSuggestions(predictions.map((p) => ({ place_id: p.place_id, description: p.description })));
          } else {
            setSuggestions([]);
          }
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.error("Erreur suggestions:", error);
      setIsLoading(false);
    }
  }, []);

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    fetchSuggestions(value);
  };

  const handleSuggestionSelect = async (suggestion) => {
    setAddress(suggestion.description);
    setSuggestions([]);
    const geocoder = new window.google.maps.Geocoder();
    try {
      const result = await geocoder.geocode({ placeId: suggestion.place_id });
      if (result.results[0]) {
        const loc = result.results[0].geometry.location;
        setLatitude(loc.lat());
        setLongitude(loc.lng());
      }
    } catch (err) {
      console.error("Erreur géocode:", err);
    }
  };

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setLatitude(lat);
          setLongitude(lng);
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK" && results[0]) {
              setAddress(results[0].formatted_address);
            }
          });
        },
        (error) => {
          console.error("Erreur géolocalisation:", error);
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put("/auth/users/me", {
        full_name,
		address,
        latitude,
        longitude,
        fumeur,
        chauffeur,
        comment,
      });
      alert(t("profile.update_success"));
      navigate("/dashboard");
    } catch (err) {
      console.error("Erreur lors de la mise à jour:", err);
      alert(t("profile.update_error"));
    }
  };

  if (loading) return <div className="text-center mt-10">{t("profile.loading")}</div>;
  const { full_name } = user;
  
  return (
    <div className="w-full max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">{t("profile.title")}</h1>
      <form onSubmit={handleSubmit} className="space-y-5">
        <div>
          <label className="block text-gray-700 font-semibold mb-1">{t("profile.name")}</label>
          <input
			type="text"
			value={fullName}
			onChange={(e) => setFullName(e.target.value)}
		    className="w-full p-3 border rounded"
		  />
        </div>

        <div>
          <label className="block text-gray-700 font-semibold mb-1">{t("profile.address")}</label>
          <div className="flex items-center gap-2">
            <button type="button" onClick={getGeolocation} className="p-2 bg-blue-500 text-white rounded-full">📍</button>
            <input
              type="text"
              value={address}
              onChange={handleAddressChange}
              className="flex-1 p-3 border rounded"
              placeholder={t("profile.address_placeholder")}
              required
            />
          </div>
          {isLoading && <p>{t("profile.loading")}</p>}
          {suggestions.length > 0 && (
            <ul className="border bg-white mt-2 max-h-40 overflow-y-auto rounded shadow">
              {suggestions.map((s, i) => (
                <li
                  key={i}
                  onClick={() => handleSuggestionSelect(s)}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                >
                  {s.description}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div>
          <label className="block text-gray-700 font-semibold mb-1">{t("profile.smoker")}</label>
          <select value={fumeur} onChange={(e) => setFumeur(e.target.value)} className="w-full p-3 border rounded" required>
            <option value="">-- {t("profile.choose")}</option>
            <option value="Oui">{t("profile.smoker_yes")}</option>
            <option value="Non">{t("profile.smoker_no")}</option>
            <option value="Pas en voiture">{t("profile.smoker_car")}</option>
          </select>
        </div>

        <div>
          <label className="block text-gray-700 font-semibold mb-1">{t("profile.driver_preference")}</label>
          <select value={chauffeur} onChange={(e) => setChauffeur(e.target.value)} className="w-full p-3 border rounded" required>
            <option value="">-- {t("profile.choose")}</option>
            <option value="Je préfère prendre ma voiture">{t("profile.driver_own")}</option>
            <option value="Je préfère me faire conduire">{t("profile.driver_other")}</option>
            <option value="Je n'ai pas de préférence">{t("profile.driver_none")}</option>
          </select>
        </div>

        <div>
          <label className="block text-gray-700 font-semibold mb-1">{t("profile.comment")}</label>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="w-full p-3 border rounded" rows="4" />
        </div>

        <button type="submit" className="w-full py-3 bg-green-500 text-white rounded hover:bg-green-600 font-semibold">
          {t("profile.update_button")}
        </button>
      </form>
    </div>
  );
};

export default Profil;