import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api";

const VerifyEmail = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const verifyAndRedirect = async () => {
            const params = new URLSearchParams(window.location.search);
            const token = params.get("token");

            if (!token) {
                console.warn("⚠️ Aucun token trouvé dans l'URL !");
                return;
            }

            localStorage.setItem("authToken", token);
            window.dispatchEvent(new Event("storage"));

            try {
                const response = await api.get("/auth/users/me", {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const user = response.data;
                const hasProfile = user.address && user.latitude && user.longitude;
                navigate(hasProfile ? "/dashboard" : "/profil");
            } catch (error) {
                console.error("Erreur lors de la récupération du profil:", error);
                navigate("/dashboard"); // Fallback si l'appel échoue
            }
        };

        verifyAndRedirect();
    }, [navigate]);

    return <h2>Validation en cours...</h2>;
};

export default VerifyEmail;
