import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/HeaderRedirect";
import EventSuccess from "./pages/EventSuccess";
import EventAdmin from "./pages/EventAdminRedirect";
import CreateEvent from "./pages/CreateEvent";
import EventPage from "./pages/EventPage";
import Home from "./pages/Home";
import Signup from "./pages/SignupRedirect";
import VerifyEmail from "./pages/VerifyEmail";
import Dashboard from "./pages/Dashboard";
import ChangePasswordPage from "./pages/ChangePassword";
import LoginPage from "./pages/LoginRedirect";
import Payment from "./pages/Payment";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";
import ProposeDonationPage from "./pages/ProposeDonationPage";
import DashboardRedirect from "./pages/DashboardRedirect";
import Profil from "./pages/Profil";
import Cgu from "./pages/Cgu";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/LanguageSelector";
import QuiSommesNous from "./pages/QuiSommesNous";
import EventPageRedirect from "./pages/EventPageRedirect";
import CreateEventRedirect from "./pages/CreateEventRedirect";
import ContactUs from "./pages/ContactUs";

import "./styles/global.css";

const loadGoogleMapsScript = () => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    if (!apiKey) {
        console.error("❌ Erreur : La clé API Google Maps est manquante !");
        return;
    }

    // Vérifie si le script est déjà présent dans le document
    if (document.querySelector(`script[src*="maps.googleapis.com"]`)) {
        return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
};

const loadRecaptchaScript = () => {
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    if (!recaptchaKey) {
        console.error("❌ Erreur : La clé API reCAPTCHA est manquante !");
        return;
    }

    // Vérifie si le script est déjà présent pour éviter de le charger plusieurs fois
    if (document.querySelector(`script[src*="www.google.com/recaptcha"]`)) {
        return;
    }
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
};

	

const App = () => {
	const { t } = useTranslation();
	
    useEffect(() => {
        loadGoogleMapsScript();
		loadRecaptchaScript();
    }, []);
	
	return (
	  <>
		<Router>
		  <Header />
		  <Routes>
			<Route path="/signup" element={<Signup />} />
			<Route path="/" element={<Home />} />
			<Route path="/event/create" element={<CreateEventRedirect />} />
			<Route path="/event/admin/:id_admin" element={<EventAdmin />} />
			<Route path="/event/success/:id_user/:id_admin" element={<EventSuccess />} />
			<Route path="/verify-email" element={<VerifyEmail />} />
			<Route path="/dashboard" element={<DashboardRedirect />} />
			<Route path="/change-password" element={<ChangePasswordPage />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/forgot-password" element={<ForgotPasswordPage />} />
			<Route path="/reset-password" element={<ResetPasswordPage />} />
			<Route path="/event/payment" element={<Payment />} />
			<Route path="/donate" element={<ProposeDonationPage />} />
			<Route path="/event/donation-proposal/:id_user/:id_admin" element={<ProposeDonationPage />} />
			<Route path="/profil" element={<Profil />} />
			<Route path="/cgu" element={<Cgu />} />
			<Route path="/qui-sommes-nous" element={<QuiSommesNous />} />
			<Route path="/event/user/:id_user" element={<EventPageRedirect />} />
			<Route path="/contact" element={<ContactUs />} />
		  </Routes>
		</Router>
	  </>
	);
};

export default App;





	