// capitals.js

export const countryCapitals = {
  FR: { lat: 48.8566, lng: 2.3522, name: "Paris" },
  BE: { lat: 50.8503, lng: 4.3517, name: "Bruxelles" },
  CH: { lat: 46.9481, lng: 7.4474, name: "Berne" },
  DE: { lat: 52.52, lng: 13.405, name: "Berlin" },
  IT: { lat: 41.9028, lng: 12.4964, name: "Rome" },
  ES: { lat: 40.4168, lng: -3.7038, name: "Madrid" },
  US: { lat: 38.9072, lng: -77.0369, name: "Washington" },
  CA: { lat: 45.4215, lng: -75.6972, name: "Ottawa" },
  UK: { lat: 51.5074, lng: -0.1278, name: "Londres" },
  AU: { lat: -35.2809, lng: 149.13, name: "Canberra" },
  NL: { lat: 52.3676, lng: 4.9041, name: "Amsterdam" },
  SE: { lat: 59.3293, lng: 18.0686, name: "Stockholm" },
  NO: { lat: 59.9139, lng: 10.7522, name: "Oslo" },
  DK: { lat: 55.6761, lng: 12.5683, name: "Copenhague" },
  FI: { lat: 60.1695, lng: 24.9354, name: "Helsinki" },
  PT: { lat: 38.7169, lng: -9.1399, name: "Lisbonne" },
  IE: { lat: 53.3498, lng: -6.2603, name: "Dublin" },
  AT: { lat: 48.2082, lng: 16.3738, name: "Vienne" },
  GR: { lat: 37.9838, lng: 23.7275, name: "Athènes" },
  CZ: { lat: 50.0755, lng: 14.4378, name: "Prague" },
  PL: { lat: 52.2297, lng: 21.0122, name: "Varsovie" }
};

export const defaultCapital = countryCapitals.FR;
